import React from 'react'
import Footer from '../../components/Footer'

function SiteCanvas(props) {

  function openEmail(e) {
    e.preventDefault()
    var form = e.target
    var name = form[0].value
    var lastName = form[1].value
    var email = form[2].value
    var comment = form[3].value

    if(!name || !lastName || !email || !comment){
      alert('Please fill all fields to submit the form')
      return;
    }

    window.open(`mailto:tvlcoin.org@gmail.com?subject=contact-form&body=${comment}`);
  }



  return (
    <div className="site-canvas">
      <div className="banner-wrap">
        <div className="wsite-elements wsite-not-footer wsite-header-elements">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-header-section wsite-section-bg-image" style={{height: 472, verticalAlign: 'bottom', backgroundImage: 'url(/images/636888661.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundColor: 'transparent'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="banner">
                    <div className="wsite-section-elements">
                      <div id={350092727783780834}>
                        <div>
                          <div id="element-5dca079b-e55c-405f-b435-c38e1fa19bd1" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                            <div className="colored-box">
                              <div className="colored-box-content">
                                <div style={{width: 'auto'}}>
                                  <div />
                                  <div id={426985745538763102}>
                                    <div>
                                      <div id="element-09b10bb9-6a3c-441f-a121-9dead6f96adc" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                        <div className="colored-box">
                                          <div className="colored-box-content">
                                            <div style={{width: 'auto'}}>
                                              <div />
                                              <div className="paragraph"><strong>
                                                  <font size={6}>CONTACT US</font>
                                                </strong></div>
                                              <div>
                                                <div className="wsite-multicol">
                                                  <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                    <table className="wsite-multicol-table">
                                                      <tbody className="wsite-multicol-tbody">
                                                        <tr className="wsite-multicol-tr">
                                                          <td className="wsite-multicol-col" style={{width: '28.765792031098%', padding: '0 15px'}}>
                                                            <div className="wsite-spacer" style={{height: 50}} />
                                                          </td>
                                                          <td className="wsite-multicol-col" style={{width: '43.537414965986%', padding: '0 15px'}}>
                                                            <div className="paragraph" style={{textAlign: 'left'}}>
                                                              <font size={2}>Please feel free to reach out with any
                                                                questions you may have.</font>
                                                            </div>
                                                          </td>
                                                          <td className="wsite-multicol-col" style={{width: '27.696793002915%', padding: '0 15px'}}>
                                                            <div className="wsite-spacer" style={{height: 50}} />
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <form onSubmit={ e => openEmail(e)} id="form-346355169494475570">
                                                  <div id="346355169494475570-form-parent" className="wsite-form-container" style={{marginTop: 0}}>
                                                    <ul className="formlist" id="346355169494475570-form-list">
                                                      <label className="wsite-form-label wsite-form-fields-required-label"><span className="form-required">*</span> Indicates required
                                                        field</label>
                                                      <div>
                                                        <div className="wsite-multicol">
                                                          <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                            <table className="wsite-multicol-table">
                                                              <tbody className="wsite-multicol-tbody">
                                                                <tr className="wsite-multicol-tr">
                                                                  <td className="wsite-multicol-col" style={{width: '30.48128342246%', padding: '0 15px'}}>
                                                                    <div className="wsite-spacer" style={{height: 50}}>
                                                                    </div>
                                                                  </td>
                                                                  <td className="wsite-multicol-col" style={{width: '69.51871657754%', padding: '0 15px'}}>
                                                                    <div>
                                                                      <div className="wsite-multicol">
                                                                        <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                                          <table className="wsite-multicol-table">
                                                                            <tbody className="wsite-multicol-tbody">
                                                                              <tr className="wsite-multicol-tr">
                                                                                <td className="wsite-multicol-col" style={{width: '82.307692307692%', padding: '0 15px'}}>
                                                                                  <div>
                                                                                    <div className="wsite-form-field wsite-name-field" style={{margin: '5px 0px 5px 0px'}}>
                                                                                      <label className="wsite-form-label">Name
                                                                                        <span className="form-required">*</span></label>
                                                                                      <div style={{clear: 'both'}} />
                                                                                      <div className="wsite-form-input-container wsite-form-left wsite-form-input-first-name">
                                                                                        <input aria-required="true" id="input-631947236785716760" className="wsite-form-input wsite-input" placeholder="First" type="text" name="_u631947236785716760[first]" />
                                                                                        <label className="wsite-form-sublabel" htmlFor="input-631947236785716760">First</label>
                                                                                      </div>
                                                                                      <div className="wsite-form-input-container wsite-form-right wsite-form-input-last-name">
                                                                                        <input aria-required="true" id="input-631947236785716760-1" className="wsite-form-input wsite-input" placeholder="Last" type="text" name="_u631947236785716760[last]" />
                                                                                        <label className="wsite-form-sublabel" htmlFor="input-631947236785716760-1">Last</label>
                                                                                      </div>
                                                                                      <div id="instructions-631947236785716760" className="wsite-form-instructions" style={{display: 'none'}} />
                                                                                    </div>
                                                                                    <div style={{clear: 'both'}} />
                                                                                  </div>
                                                                                </td>
                                                                                <td className="wsite-multicol-col" style={{width: '17.692307692308%', padding: '0 15px'}}>
                                                                                  <div className="wsite-spacer" style={{height: 50}} />
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div className="wsite-multicol">
                                                          <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                            <table className="wsite-multicol-table">
                                                              <tbody className="wsite-multicol-tbody">
                                                                <tr className="wsite-multicol-tr">
                                                                  <td className="wsite-multicol-col" style={{width: '30.448717948718%', padding: '0 15px'}}>
                                                                    <div className="wsite-spacer" style={{height: 50}}>
                                                                    </div>
                                                                  </td>
                                                                  <td className="wsite-multicol-col" style={{width: '48.183760683761%', padding: '0 15px'}}>
                                                                    <div>
                                                                      <div className="wsite-form-field" style={{margin: '5px 0px 5px 0px'}}>
                                                                        <label className="wsite-form-label" htmlFor="input-940537981460640694">Email <span className="form-required">*</span></label>
                                                                        <div className="wsite-form-input-container">
                                                                          <input aria-required="true" id="input-940537981460640694" className="wsite-form-input wsite-input wsite-input-width-370px" type="text" name="_u940537981460640694" />
                                                                        </div>
                                                                        <div id="instructions-940537981460640694" className="wsite-form-instructions" style={{display: 'none'}} />
                                                                      </div>
                                                                    </div>
                                                                  </td>
                                                                  <td className="wsite-multicol-col" style={{width: '21.367521367521%', padding: '0 15px'}}>
                                                                    <div className="wsite-spacer" style={{height: 50}}>
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div className="wsite-multicol">
                                                          <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                            <table className="wsite-multicol-table">
                                                              <tbody className="wsite-multicol-tbody">
                                                                <tr className="wsite-multicol-tr">
                                                                  <td className="wsite-multicol-col" style={{width: '30.48128342246%', padding: '0 15px'}}>
                                                                    <div className="wsite-spacer" style={{height: 50}}>
                                                                    </div>
                                                                  </td>
                                                                  <td className="wsite-multicol-col" style={{width: '69.51871657754%', padding: '0 15px'}}>
                                                                    <div>
                                                                      <div className="wsite-multicol">
                                                                        <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                                          <table className="wsite-multicol-table">
                                                                            <tbody className="wsite-multicol-tbody">
                                                                              <tr className="wsite-multicol-tr">
                                                                                <td className="wsite-multicol-col" style={{width: '66.307692307692%', padding: '0 15px'}}>
                                                                                  <div>
                                                                                    <div className="wsite-form-field" style={{margin: '5px 0px 5px 0px'}}>
                                                                                      <label className="wsite-form-label" htmlFor="input-106754620564322347">Comment
                                                                                        <span className="form-required">*</span></label>
                                                                                      <div className="wsite-form-input-container">
                                                                                        <input aria-required="true" id="input-106754620564322347" className="wsite-form-input wsite-input wsite-input-width-370px" type="text" name="_u106754620564322347" />
                                                                                      </div>
                                                                                      <div id="instructions-106754620564322347" className="wsite-form-instructions" style={{display: 'none'}} />
                                                                                    </div>
                                                                                  </div>
                                                                                </td>
                                                                                <td className="wsite-multicol-col" style={{width: '33.692307692308%', padding: '0 15px'}}>
                                                                                  <div className="wsite-spacer" style={{height: 50}} />
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </ul>
                                                  </div>
                                                  <div style={{display: 'none', visibility: 'hidden'}}>
                                                    <input type="hidden" name="wsite_subject" />
                                                  </div>
                                                  <div style={{textAlign: 'center', marginTop: 10, marginBottom: 0}}>
                                                    {/* <input type="hidden" name="form_version" defaultValue={2} />
                                                    <input type="hidden" name="wsite_approved" id="wsite-approved" defaultValue="approved" />
                                                    <input type="hidden" name="ucfid" defaultValue={346355169494475570} />
                                                    <input type="hidden" name="recaptcha_token" />
                                                    <input type="submit" role="button" aria-label="Submit" defaultValue="Submit" style={{position: 'absolute', top: 0, left: '-9999px', width: 1, height: 1}} />
                                                    <a className="wsite-button">
                                                      <span className="wsite-button-inner">Submit</span>
                                                    </a> */}
                                                    <button className='contact-form-submit' type='submit'>Submit</button>
                                                  </div>
                                                </form>
                                                <div id="g-recaptcha-346355169494475570" className="recaptcha" data-size="invisible" data-recaptcha={1} data-sitekey="6Ldf5h8UAAAAAJFJhN6x2OfZqBvANPQcnPa8eb1C" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div style={{clear: 'both'}} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{clear: 'both'}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-wrap">
        <div id="wsite-content" className="wsite-elements wsite-not-footer">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-background-43" style={{height: 10}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className="mini-cart-screen" />
    </div>
  )
}

export default SiteCanvas