import React from 'react'
import Footer from '../../components/Footer'

function SiteCanvas(params) {
  return (
    <div className="site-canvas">
      <div className="banner-wrap">
        <div className="wsite-elements wsite-not-footer wsite-header-elements">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-header-section wsite-section-bg-image" style={{backgroundImage: 'url(/images/1459596380.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'undefined undefined', backgroundSize: 'cover', backgroundColor: 'transparent'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="banner">
                    <div className="wsite-section-elements">
                      <div className="wsite-spacer" style={{height: 190}} />
                      <h2 className="wsite-content-title" style={{textAlign: 'center'}}>
                        <font size={6}>DISCOVER THE WORLD WITH TRAVEL COIN</font>
                      </h2>
                      <div className="wsite-spacer" style={{height: 387}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-wrap">
        <div id="wsite-content" className="wsite-elements wsite-not-footer">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-section-bg-gradient wsite-background-25" style={{background: 'linear-gradient(180deg, #fbfcfc 48%, #063970 100%)'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <div id={966539830676527398}>
                      <div>
                        <div id="element-4463d457-fb26-4a92-a9f9-ca74878acb20" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                          <div id="container-box">
                            <div className="outer-box hover-">
                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                <div className="content">
                                  <div style={{width: 'auto'}}>
                                    <div />
                                    <div id={287026338242563386}>
                                      <div>
                                        <div id="element-b41fdac1-bb49-441c-942b-c0d2a3a12993" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                          <div id="container-box">
                                            <div className="outer-box hover-">
                                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                <div className="content">
                                                  <div style={{width: 'auto'}}>
                                                    <div />
                                                    <div id={714906994905383409}>
                                                      <div>
                                                        <div id="element-a285ca85-e719-45eb-82e7-d6a35e1258b7" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                          <div className="colored-box">
                                                            <div className="colored-box-content">
                                                              <div style={{width: 'auto'}}>
                                                                <div />
                                                                <div>
                                                                  <div className="wsite-image wsite-image-border-none " style={{paddingTop: 10, paddingBottom: 10, marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                                                                    <a>
                                                                      <img src="/images/tvl-coin-logo_orig.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                    </a>
                                                                    <div style={{display: 'block', fontSize: '90%'}} />
                                                                  </div>
                                                                </div>
                                                                <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong>
                                                                    <font color="#2a2a2a">ABOUT&nbsp;CUSTOM TRAVEL
                                                                      SOLUTIONS&nbsp;​</font>
                                                                  </strong></h2>
                                                                <div className="paragraph">Since July 1998 and with access
                                                                  to clients from travel trade, airlines and hotels in
                                                                  over 200+ countries, our team has been building a
                                                                  strong, reliable, well respected travel business and
                                                                  global media platform with new technologies that
                                                                  enable companies and organizations around the world to
                                                                  drive sales and raise awareness’s on the
                                                                  responsible, sustainable travel tourism
                                                                  industry.<br /><br />Our many years of learning,
                                                                  understanding, gained knowledge, expertise and
                                                                  capabilities, as well as creating strong well
                                                                  respected extensive relationships that provides new
                                                                  benefits and solutions for customers, companies the
                                                                  ability to use, adapt into new easy-to-use
                                                                  platforms.&nbsp;&nbsp;We deliver travel and lifestyle
                                                                  benefits for the fast growing travel industry
                                                                  needs.&nbsp;<br />Above all, our commitment is to help
                                                                  the travel, trade industry to build, and operate
                                                                  successful travel membership programs through use of
                                                                  NFTs, easy to use universal travel currency that
                                                                  allows customers to benefit from the right offers,
                                                                  packages, select the best possible vacations, tailored
                                                                  travel deals, promotions, incentives, increased
                                                                  loyalty and save on destinations from around the globe
                                                                  with rewards.<br /><br /><strong> TRAVEL COIN </strong>
                                                                  NFT sales will allow&nbsp;members to offer vacations
                                                                  for large and small groups, weddings, honeymoons, or
                                                                  the first time trips to exotic places from Bora Bora,
                                                                  Seychelles, African safaris or luxury ocean cruises
                                                                  and more.<br /><br /><strong>How NFTs will work for
                                                                    you</strong><br />Travel members, industry team
                                                                  agents do whatever necessary to plan, make available,
                                                                  from finding the best airfare prices, the top hotels,
                                                                  or car rentals and tours, for travellers to benefit
                                                                  all of the planning and coordinating for you.<br />Our
                                                                  model is to build respected, sustainable and
                                                                  responsible relationships between the travel industry
                                                                  and travellers adopting to new technologies.</div>
                                                                <div style={{textAlign: 'center'}}>
                                                                  <div style={{height: 10, overflow: 'hidden'}} />
                                                                  <a className="wsite-button wsite-button-small wsite-button-highlight" href="/images/travel_coin_whitepaper.pdf">
                                                                    <span className="wsite-button-inner">WHITEPAPER</span>
                                                                  </a>
                                                                  <div style={{height: 10, overflow: 'hidden'}} />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{clear: 'both'}} />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                        </div>
                                        <div style={{clear: 'both'}} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                        </div>
                        <div style={{clear: 'both'}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <div className="mini-cart-screen" />
    </div>
  )
}

export default SiteCanvas