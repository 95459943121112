import React from 'react'
import { Link  } from 'react-router-dom'

function Footer(props) {

  return(
    <div className="footer-wrap">
      <div className="container">
        <div className="footer">
          <div className="wsite-elements wsite-footer">
            <div>
              <div className="wsite-multicol">
                <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                  <table className="wsite-multicol-table">
                    <tbody className="wsite-multicol-tbody">
                      <tr className="wsite-multicol-tr">
                        <td className="wsite-multicol-col" style={{width: '20%', padding: '0 15px'}}>
                          <div>
                            <div className="wsite-image wsite-image-border-none " style={{paddingTop: 10, paddingBottom: 10, marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                              <a href="https://t.me/TravelCoinTVL" target="_blank">
                                <img src="/images/telegram-32_orig.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                              </a>
                              <div style={{display: 'block', fontSize: '90%'}} />
                            </div>
                          </div>
                        </td>
                        <td className="wsite-multicol-col" style={{width: '20%', padding: '0 15px'}}>
                          <div>
                            <div className="wsite-image wsite-image-border-none " style={{paddingTop: 10, paddingBottom: 10, marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                              <a href="https://twitter.com/TravelCoinTVL" target="_blank">
                                <img src="/images/twitter-32_orig.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                              </a>
                              <div style={{display: 'block', fontSize: '90%'}} />
                            </div>
                          </div>
                        </td>
                        <td className="wsite-multicol-col" style={{width: '20%', padding: '0 15px'}}>
                          <div>
                            <div className="wsite-image wsite-image-border-none " style={{paddingTop: 10, paddingBottom: 10, marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                              <a href='https://github.com/TravelCoinTVL'>
                                <img src="/images/291716-github-logo-social-network-social-icon_orig.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                              </a>
                              <div style={{display: 'block', fontSize: '90%'}} />
                            </div>
                          </div>
                        </td>
                        {/* <td className="wsite-multicol-col" style={{width: '20%', padding: '0 15px'}}>
                          <div>
                            <div className="wsite-image wsite-image-border-none " style={{paddingTop: 10, paddingBottom: 10, marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                              <a href="https://discord.gg/wc4RAS5ZzV" target="_blank">
                                <img src="/images/discord-32_orig.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                              </a>
                              <div style={{display: 'block', fontSize: '90%'}} />
                            </div>
                          </div>
                        </td> */}
                        <td className="wsite-multicol-col" style={{width: '20%', padding: '0 15px'}}>
                          <div>
                            <div className="wsite-image wsite-image-border-none " style={{paddingTop: 10, paddingBottom: 10, marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                              <a href='https://bscscan.com/token/0xabebb40b06c99d76027c8cfe57186b297d00d631'>
                                <img src="/images/bsc.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                              </a>
                              <div style={{display: 'block', fontSize: '90%'}} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="paragraph" style={{textAlign: 'center'}}>
              <font color="#2a2a2a"> TRAVEL COIN™ ​is a registered trademark to Focus Media UK, Switzerland
                and&nbsp;USA</font><br /><strong>
                <font color="#2a2a2a">Copyright © 2012-2022 TRAVEL COIN. All rights reserved. Invest
                  responsibly.</font>
              </strong><br />
              <font color="#2a2a2a">​</font><strong>
                <Link to={'/terms-conditions'} > Terms &amp; conditions </Link>. 
                <Link to={'/privacy-policy'} > Privacy policy </Link>. 
                <Link to={'/disclaimer'} target="_blank" > Disclaimer </Link> </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer