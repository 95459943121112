import React from "react"
import NFT from "./views/ntf"
import Home from "./views/home"
import Contact from "./views/Contact"
import AboutUS from "./views/AboutUs"
import Metaverse from "./views/Metaverse"
import Disclaimer from "./views/Disclaimer"
import VirtualTravel from "./views/VirtualTravel"
import PrivacyPolicy from "./views/PrivacyPolicy"
import TravelIndustry from "./views/TravelIndustry"
import TermsConditions from "./views/TermsConditions"
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom'

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={ <Home /> } />
        <Route exact path="/nft" element={ <NFT /> } />
        <Route exact path="/contact" element={ <Contact /> } />
        <Route exact path="/about-us" element={ <AboutUS /> } />
        <Route exact path="/disclaimer" element={ <Disclaimer /> } />
        <Route exact path="/metaverse" element={ <Metaverse /> } />
        <Route exact path="/virtual-travel" element={ <VirtualTravel /> } />
        <Route exact path="/privacy-policy" element={ <PrivacyPolicy /> } />
        <Route exact path="/travel-industry" element={ <TravelIndustry /> } />
        <Route exact path="/terms-conditions" element={ <TermsConditions /> } />
        <Route path="*" element={<div>404 Not found</div>}/>
      </Routes>
    </Router>
  )
}

export default App;
