import React from 'react'
import Footer from '../../components/Footer'

function SiteCanvas(params) {
  return (
    <div className="site-canvas">
      <div className="banner-wrap">
        <div className="wsite-elements wsite-not-footer wsite-header-elements">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-header-section wsite-section-bg-image" style={{backgroundImage: 'url("//cdn2.editmysite.com/images/editor/theme-background/stock/Bridge.jpg")', backgroundRepeat: 'no-repeat', backgroundPosition: '50.00% 37.61%', backgroundSize: 'cover', backgroundColor: 'transparent'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="banner">
                    <div className="wsite-section-elements">
                      <h2 className="wsite-content-title" />
                    </div>
                  </div>
                </div>
              </div>
              <div className />
            </div>
          </div>
        </div>
      </div>
      <div className="main-wrap">
        <div id="wsite-content" className="wsite-elements wsite-not-footer">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-background-59">
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <h2 className="wsite-content-title"><strong>DISCLAIMER</strong></h2>
                    <div className="paragraph">
                      <font size={1}>This website sets out the current and future developments of the Travel Coin 
                        ecosystem and the $TVL token. The $TVL token is issued and managed by a dedicated entity wholly
                        separate from Travel Coin website. This <span style={{color: 'rgb(63, 63, 63)'}}>website</span> is
                        made available solely for the purpose of providing information and is not a statement of future
                        intent. The information contained on this <span style={{color: 'rgb(63, 63, 63)'}}>website</span> has
                        not been approved by any regulatory authority and may be amended, updated, or otherwise modified
                        at any time. In publishing this <span style={{color: 'rgb(63, 63, 63)'}}>website</span>, Travel Coin,  
                        its related bodies corporate, its directors, staff and any other contributors make no warranties
                        or representations of any kind, including with respect to successfully achieving the development
                        or implementation of the technologies, innovations, or any other activities noted on this <span style={{color: 'rgb(63, 63, 63)'}}>website</span>, and to the maximum extent permitted by law, any
                        warranties implied by law or otherwise are hereby disclaimed.</font><br /><br />
                      <font size={1}>While every effort has been made to ensure the accuracy of the information
                        contained on this <span style={{color: 'rgb(63, 63, 63)'}}>website</span> (including but not limited
                        to all statements, opinions, forecasts, or projections, whether expressed or implied), we make
                        no guarantee that the information on this <span style={{color: 'rgb(63, 63, 63)'}}>website</span> is
                        accurate, complete, or up to date.</font><br /><br />
                      <font size={1}>The plans and projections mentioned on this website may not be achieved, either in
                        part or in whole, due to multiple risk factors such as (without limitation): changes in
                        legislation or regulation, defects in technology developments, corporate actions, market or
                        sector volatility, or the unavailability of complete and accurate information. In the event that
                        changes to legislation or regulations make it illegal or commercially unviable to operate in a
                        particular jurisdiction, some or all plans and/or technologies described on this <span style={{color: 'rgb(63, 63, 63)'}}>website</span> may not be deliverable to, or may cease to be
                        offered to, users in certain jurisdictions.</font><br /><br />
                      <font size={1}>The functionality of the blockchain protocols on which the $TVL token is issued
                        falls under the control of third parties, and the parties operating these respective protocols
                        are accountable for any malfunction or adverse changes to these protocols which may negatively
                        affect the $TVL token. Other risks, such as majority mining power attacks, double-spend attacks,
                        or advances in quantum computing may compromise the security and usability of these protocols
                        and, by extension, the security and usability of the $TVL token.</font><br /><br />
                      <font size={1}>This <span style={{color: 'rgb(63, 63, 63)'}}>website</span> is not directed to, or
                        intended for distribution to or use by, any person or entity who is a citizen or resident of or
                        located in any state, country or other jurisdiction where such distribution, publication,
                        availability or use would be contrary to applicable laws or regulations. This <span style={{color: 'rgb(63, 63, 63)'}}>website</span> is not a prospectus or offer document of any kind
                        and is not intended to constitute an offer of securities or a solicitation for investment in
                        securities in any jurisdiction. The content contained on this <span style={{color: 'rgb(63, 63, 63)'}}>website</span> should not be interpreted as professional
                        financial, tax, legal, or business advice, nor as a provision for any type of advice.</font>
                      <br />
                      <font size={1}>By accessing this <span style={{color: 'rgb(63, 63, 63)'}}>website</span>, the recipient
                        acknowledges that they have read and understood this disclaimer and agree to be bound by the
                        limitations stipulated in this disclaimer.</font>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className="mini-cart-screen" />
    </div>
  )
}

export default SiteCanvas