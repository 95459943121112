import React from 'react'
import Footer from '../../components/Footer'

function SiteCanvas(params) {
  return (
    <div className="site-canvas">
      <div className="banner-wrap">
        <div className="wsite-elements wsite-not-footer wsite-header-elements">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-header-section wsite-section-bg-video wsite-section-effect-reveal" style={{height: 747, backgroundImage: 'url("/images/main_493.jpg")'}} data-image data-position="fill" data-is_customized={1} data-backgroundposition="50.00% 0.00%" data-video_type="upload" data-video_url="/images/main_493.mp4" data-video_id="/images/main_493.mp4" data-loop={1} data-autoplay={1} data-mute={1} data-background_url="/images/main_493.jpg" data-encoding_done={1}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="banner">
                    <div className="wsite-section-elements">
                    </div>
                  </div>
                </div>
              </div>

              <div className="uploadplayer-container" id="uploadplayer-container-1641734849900">
                <div id="uploadplayer-player-1641734849900" className="uploadplayer-player video-js vjs-controls-disabled vjs-has-started vjs-user-inactive vjs-playing" style={{width: 300, height: 150}}><video className="vjs-tech" id="uploadplayer-player-1641734849900_html5_api" poster="/images/main_493.jpg" loop preload="none" autoPlay src="/images/main_493.mp4" __idm_id__={4005889}>
                    <source src="/images/main_493.mp4" type="video/mp4" /></video>
                  <div />
                  <div className="vjs-poster" tabIndex={-1} style={{backgroundImage: 'url("/images/main_493.jpg")', display: 'none'}} />
                  <div className="vjs-text-track-display" />
                  <div className="vjs-loading-spinner" style={{display: 'none'}} />
                  <div className="vjs-big-play-button" aria-live="polite" tabIndex={0} aria-label="play video"><span /></div>
                  <div className="vjs-control-bar">
                    <div className="vjs-play-control vjs-control  vjs-playing" aria-live="polite" tabIndex={0}>
                      <div className="vjs-control-content"><span className="vjs-control-text">Pause</span></div>
                    </div>
                    <div className="vjs-current-time vjs-time-controls vjs-control">
                      <div className="vjs-current-time-display" aria-live="off"><span className="vjs-control-text">Current Time </span>0:12
                      </div>
                    </div>
                    <div className="vjs-time-divider">
                      <div><span>/</span></div>
                    </div>
                    <div className="vjs-duration vjs-time-controls vjs-control">
                      <div className="vjs-duration-display" aria-live="off"><span className="vjs-control-text">Duration Time </span>0:14</div>
                    </div>
                    <div className="vjs-remaining-time vjs-time-controls vjs-control">
                      <div className="vjs-remaining-time-display" aria-live="off"><span className="vjs-control-text">Remaining Time
                        </span>-0:02</div>
                    </div>
                    <div className="vjs-progress-control vjs-control">
                      <div aria-valuenow="86.16" aria-valuemin={0} aria-valuemax={100} tabIndex={0} className="vjs-progress-holder vjs-slider" aria-label="video progress bar" aria-valuetext="0:12">
                        <div className="vjs-load-progress" style={{width: '0%'}}><span className="vjs-control-text">Loaded: 0%</span></div>
                        <div className="vjs-play-progress" style={{width: '85.91%'}}><span className="vjs-control-text">Progress: 0%</span></div>
                        <div className="vjs-seek-handle vjs-slider-handle" style={{left: '85.56%'}}><span className="vjs-control-text">00:00</span></div>
                      </div>
                    </div>
                    <div className="vjs-fullscreen-control vjs-control " aria-live="polite" tabIndex={0}>
                      <div className="vjs-control-content"><span className="vjs-control-text">Fullscreen</span></div>
                    </div>
                    <div className="vjs-volume-control vjs-control">
                      <div aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} tabIndex={0} className="vjs-volume-bar vjs-slider" aria-label="volume level" aria-valuetext="100%">
                        <div className="vjs-volume-level" style={{width: '0%'}}><span className="vjs-control-text" /></div>
                        <div className="vjs-volume-handle vjs-slider-handle" style={{left: '0%'}}><span className="vjs-control-text">00:00</span>
                        </div>
                      </div>
                    </div>
                    <div className="vjs-mute-control vjs-control vjs-vol-0" aria-live="polite" tabIndex={0}>
                      <div><span className="vjs-control-text">Unmute</span></div>
                    </div>
                    <div className="vjs-subtitles-button vjs-menu-button vjs-control " aria-live="polite" tabIndex={0} aria-haspopup="true" role="button" aria-label="Subtitles Menu" style={{display: 'none'}}>
                      <div className="vjs-control-content"><span className="vjs-control-text">Subtitles</span></div>
                      <div className="vjs-menu">
                        <ul className="vjs-menu-content">
                          <li className="vjs-menu-item vjs-selected" aria-live="polite" tabIndex={0} aria-selected="true">subtitles off
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="vjs-captions-button vjs-menu-button vjs-control " aria-live="polite" tabIndex={0} aria-haspopup="true" role="button" aria-label="Captions Menu" style={{display: 'none'}}>
                      <div className="vjs-control-content"><span className="vjs-control-text">Captions</span></div>
                      <div className="vjs-menu">
                        <ul className="vjs-menu-content">
                          <li className="vjs-menu-item vjs-selected" aria-live="polite" tabIndex={0} aria-selected="true">captions off
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="vjs-chapters-button vjs-menu-button vjs-control " aria-live="polite" tabIndex={0} aria-haspopup="true" role="button" aria-label="Chapters Menu" style={{display: 'none'}}>
                      <div className="vjs-control-content"><span className="vjs-control-text">Chapters</span></div>
                      <div className="vjs-menu">
                        <ul className="vjs-menu-content" />
                        <li className="vjs-menu-title">Chapters</li>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uploadplayer-shield" />
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="main-wrap">
        <div id="wsite-content" className="wsite-elements wsite-not-footer">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-background-10" style={{height: 'auto'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <div id={185888671379261589}>
                      <div>
                        <div id="element-d34c7c82-6f75-4ab6-ac0c-eeb015fe4039" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                          <div id="container-box">
                            <div className="outer-box hover-">
                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                <div className="content">
                                  <div style={{width: 'auto'}}>
                                    <div />
                                    <div id={206398721801373486}>
                                      <div>
                                        <div id="element-cd1d1c1b-bced-4883-8416-ace9b03e99c7" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                          <div id="container-box">
                                            <div className="outer-box hover-">
                                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                <div className="content">
                                                  <div style={{width: 'auto'}}>
                                                    <div />
                                                    <div id={251062708943963632}>
                                                      <div>
                                                        <div id="element-712aff9b-3ef1-4160-8aa1-ca8e669a272c" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                          <div className="colored-box">
                                                            <div className="colored-box-content">
                                                              <div style={{width: 'auto'}}>
                                                                <div />
                                                                <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong>NFTs - Non-Fungible
                                                                    TOKENS</strong></h2>
                                                                <div className="paragraph">
                                                                  <font color="#2a2a2a"><strong> TRAVEL COIN
                                                                      NFTs&nbsp;</strong></font><br /><br /><strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL COIN</strong>
                                                                  <font color="#2a2a2a"> has created a NFT marketplace
                                                                    for buying, selling and trading of specific
                                                                    NFTs.&nbsp;&nbsp;</font><strong style={{color: 'rgb(42, 42, 42)'}}> TRAVEL COIN </strong>
                                                                  <font color="#2a2a2a"> will also have its own NFT
                                                                    created collections as well as collaborations with
                                                                    other travel and tourism related companies and
                                                                    institutions.&nbsp;&nbsp;The NFT marketplace can be
                                                                    used for exchanges or sales.&nbsp;&nbsp;Users can
                                                                    opt to trade digital assets for another unique or
                                                                    scarce digital asset.&nbsp;&nbsp;At the same time,
                                                                    they can be traded for </font><strong style={{color: 'rgb(42, 42, 42)'}}> TRAVEL COIN </strong>
                                                                  <font color="#2a2a2a"> tokens $TVL at an agreed price
                                                                    based on the algorithm valuation between the
                                                                    cryptocurrencies or fiat
                                                                    currencies.<br />&nbsp;<br />Users can trade </font>
                                                                  <strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL
                                                                    COIN</strong>
                                                                  <font color="#2a2a2a"> tokens $TVL and other NFT items
                                                                    on the Marketplace.&nbsp;&nbsp;Products up for sale
                                                                    can be from stakeholders, destination partners or
                                                                    users and they are bought with </font><strong style={{color: 'rgb(42, 42, 42)'}}> TRAVEL COIN</strong>
                                                                  <font color="#2a2a2a"> tokens
                                                                    $TVL.&nbsp;&nbsp;<br />This is considered an
                                                                    exchange of Non-Fungible Token (NFT) and Fungible
                                                                    Token (</font><strong style={{color: 'rgb(42, 42, 42)'}}> TRAVEL COIN </strong>
                                                                  
                                                                  <font color="#2a2a2a"> token
                                                                    $TVL).<br />&nbsp;<br />
                                                                  </font>
                                                                  All commodities in the <strong>TRAVEL COIN</strong> marketplace are NFTs that are unique and different from all other existing commodities. The main currency in the Marketplace is the <strong>TRAVEL COIN</strong> token $TVL and all transactions are made in <strong>TRAVEL COIN</strong>
                                                                  <font color="#2a2a2a"> token $TVL.<br />&nbsp;
                                                                    
                                                                    <br />The platform will be scalable and able to develop with the BSC blockchain infrastructure.</font>
                                                                </div>
                                                                <blockquote style={{textAlign: 'center'}}><strong>
                                                                    <font size={5} color="#063970"> TRAVEL COIN NFTs
                                                                      provide a real world travel experience.</font>
                                                                  </strong></blockquote>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{clear: 'both'}} />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                        </div>
                                        <div style={{clear: 'both'}} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                        </div>
                        <div style={{clear: 'both'}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-section-bg-image wsite-background-24" style={{height: 810, backgroundImage: 'url(/images/1002163161.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: '0.00% 71.33%', backgroundSize: 'cover', backgroundColor: 'transparent'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-background-23">
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <div id={802222622454243340}>
                      <div>
                        <div id="element-7ebeea88-d4e5-410b-9ec1-33400cd764b8" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                          <div id="container-box">
                            <div className="outer-box hover-">
                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                <div className="content">
                                  <div style={{width: 'auto'}}>
                                    <div />
                                    <div id={628178410615460476}>
                                      <div>
                                        <div id="element-64b7ba17-0dc8-4c11-9037-c1725c71556d" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                          <div id="container-box">
                                            <div className="outer-box hover-">
                                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                <div className="content">
                                                  <div style={{width: 'auto'}}>
                                                    <div />
                                                    <div id={282338664992061378}>
                                                      <div>
                                                        <div id="element-57a6acf7-ddf0-4e75-9e33-f0f0f78d0b18" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                          <div className="colored-box">
                                                            <div className="colored-box-content">
                                                              <div style={{width: 'auto'}}>
                                                                <div />
                                                                <div className="paragraph" style={{textAlign: 'left'}}>
                                                                  <font color="#2a2a2a"><strong> TRAVEL COIN</strong> is
                                                                    able to offer lower priced travel experiences as
                                                                    NFTs.<br />Investors/NFT buyers are rewarded as
                                                                    tourism returns as the tangible value of each
                                                                    entitlement NFT can be sold or traded at higher
                                                                    market rates - anywhere from 3-5 times higher
                                                                    than purchase price.<br />NFTs/investor buyers are
                                                                    rewarded as when tourism returns the demand
                                                                    increases and scarcity of the minted NFTs become
                                                                    rare and valuable.<br />&nbsp;<br />Ecosystem is
                                                                    fuelled by the </font><strong style={{color: 'rgb(42, 42, 42)'}}> TRAVEL COIN</strong>
                                                                  <font color="#2a2a2a"> token $TVL which gains
                                                                    liquidity form the sale of NFTs.<br />Stakers of
                                                                  </font><strong style={{color: 'rgb(42, 42, 42)'}}> TRAVEL
                                                                    COIN</strong>
                                                                  <font color="#2a2a2a"> earn $TVL tokens used to trade
                                                                    for the different experiences, hotels,
                                                                    etc.<br />Investors are interested to buy luxury
                                                                    holiday experiences as NFTs to either use or sell
                                                                    when the tourism picks up after the pandemic.</font>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{clear: 'both'}} />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                        </div>
                                        <div style={{clear: 'both'}} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                        </div>
                        <div style={{clear: 'both'}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className="mini-cart-screen" />
    </div>
  )
}

export default SiteCanvas