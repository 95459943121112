import React from 'react'
import { NavLink  } from 'react-router-dom'

function Header(props) {
  return(
    <div className="dusk-header">
      <div className="nav-wrap">
        <div className="dummy-nav">
          <ul className="wsite-menu-default">
            <li className="wsite-menu-item-wrap">
              <NavLink className="wsite-menu-item" to={'/'} ><span>Home</span></NavLink>
            </li>
            <li id="pg965204828521206270" className="wsite-menu-item-wrap">
              <NavLink className="wsite-menu-item" to={'/nft'} ><span>NFT</span></NavLink>
            </li>
            <li id="pg119908896110903504" className="wsite-menu-item-wrap">
              <NavLink className="wsite-menu-item" to={'/virtual-travel'} ><span>Virtual Travel</span></NavLink>
            </li>
            <li id="pg925135855845673161" className="wsite-menu-item-wrap">
              <NavLink className="wsite-menu-item" to={'/travel-industry'} ><span>Travel Industry</span></NavLink>
            </li>
            <li id="pg410697422713427165" className="wsite-menu-item-wrap">
              <NavLink className="wsite-menu-item" to={'/about-us'} ><span>About Us</span></NavLink>
              <div className="wsite-menu-wrap" style={{display: 'none'}}>
                <ul className="wsite-menu">
                  <li id="wsite-nav-100258316390562804" className="wsite-menu-subitem-wrap ">
                    <NavLink className="wsite-menu-subitem" to={'/contact'} ><span>Contact</span></NavLink>
                  </li>
                  <li id="wsite-nav-243338099441262481" className="wsite-menu-subitem-wrap ">
                    <NavLink  target="_blank" className="wsite-menu-item" to={'/images/travel_coin_whitepaper.pdf'} ><span className="wsite-menu-title">WHITEPAPER</span></NavLink>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className="container">
          <button className="hamburger">
            <svg width={22} height={15} viewBox="0 0 22 15" xmlns="http://www.w3.org/2000/svg">
              <g fill="currentColor" fillRule="evenodd">
                <path d="M20.308 2.256H1.128C.505 2.256 0 1.75 0 1.128 0 .505.505 0 1.128 0h19.18c.623 0 1.128.505 1.128 1.128 0 .623-.505 1.128-1.128 1.128zM20.308 8.462H1.128C.505 8.462 0 7.956 0 7.332c0-.622.505-1.127 1.128-1.127h19.18c.623 0 1.128.505 1.128 1.128 0 .623-.505 1.13-1.128 1.13zM20.308 14.667H1.128c-.623 0-1.128-.505-1.128-1.13 0-.622.505-1.127 1.128-1.127h19.18c.623 0 1.128.505 1.128 1.128 0 .624-.505 1.13-1.128 1.13z" />
              </g>
            </svg>
            <span className="sr-only">Menu</span>
          </button>
          <div className="logo">
            <span className="wsite-logo">
              <NavLink to={'/'} ><span id="wsite-title"> TRAVEL COIN</span></NavLink>
            </span>
          </div>
          <div className="nav desktop-nav">
            <ul className="wsite-menu-default">
              <li className="wsite-menu-item-wrap">
                <NavLink className="wsite-menu-item" to={'/'} ><span>Home</span></NavLink>
              </li>
              <li id="pg965204828521206270" className="wsite-menu-item-wrap">
                <NavLink className="wsite-menu-item" to={'/nft'} ><span>NFT</span></NavLink>
              </li>
              <li id="pg119908896110903504" className="wsite-menu-item-wrap">
                <NavLink className="wsite-menu-item" to={'/virtual-travel'} ><span>Virtual Travel</span></NavLink>
              </li>
              <li id="pg925135855845673161" className="wsite-menu-item-wrap">
                <NavLink className="wsite-menu-item" to={'/metaverse'} ><span>Metaverse</span></NavLink>
              </li>
              <li id="pg925135855845673161" className="wsite-menu-item-wrap">
                <NavLink className="wsite-menu-item" to={'/travel-industry'} ><span>Travel Industry</span></NavLink>
              </li>
              <li id="pg410697422713427165" className="wsite-menu-item-wrap">
                <NavLink className="wsite-menu-item" to={'/about-us'} ><span>About Us</span></NavLink>
                <div className="wsite-menu-wrap" style={{display: 'none'}}>
                  <ul className="wsite-menu">
                    <li id="wsite-nav-100258316390562804" className="wsite-menu-subitem-wrap ">
                      
                      <NavLink className="wsite-menu-subitem" to={'/contact'} ><span className="wsite-menu-title">
                          Contact
                        </span></NavLink>
                    </li>
                    <li id="wsite-nav-243338099441262481" className="wsite-menu-subitem-wrap ">
                      <NavLink  target="_blank" className="wsite-menu-subitem" to={'/images/travel_coin_whitepaper.pdf'} ><span className="wsite-menu-title">WHITEPAPER</span></NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <span className="wsite-search">
              <form id="wsite-header-search-form" action="/apps/search" method="get">
                <input type="text" name="q" className="wsite-search-input" autoComplete="off" placeholder="Search" /><span className="wsite-search-button"><span className="wsite-search-button-inner" /></span>
              </form>
            </span>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Header