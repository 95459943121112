import React from 'react'


function MobileNav(params) {
  return(
    <div className="nav mobile-nav">
      <button className="mobile-nav-toggle">
        <svg width={20} height={20} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.376 10l7.13-7.13c.656-.657.656-1.72 0-2.378-.656-.656-1.72-.656-2.377 0L10 7.622 2.87.493C2.212-.164 1.15-.164.49.492c-.656.657-.656 1.72 0 2.377L7.622 10l-7.13 7.13c-.656.656-.656 1.72 0 2.376.657.656 1.72.656 2.377 0l7.13-7.13 7.13 7.13c.656.656 1.72.656 2.376 0 .656-.656.656-1.72 0-2.377L12.376 10z" fill="currentColor" fillRule="evenodd" />
        </svg>
      </button>
      <ul className="wsite-menu-default">
        <li id="active" className="wsite-menu-item-wrap">
          <a href="/" className="wsite-menu-item">
            <span>Home</span>
          </a>
        </li>
        <li id="pg965204828521206270" className="wsite-menu-item-wrap">
          <a href="/nft.html" className="wsite-menu-item">
            <span>NFT</span>
          </a>
        </li>
        <li id="pg119908896110903504" className="wsite-menu-item-wrap">
          <a href="/virtual-travel.html" className="wsite-menu-item">
            <span>Virtual Travel</span>
          </a>
        </li>
        <li id="pg925135855845673161" className="wsite-menu-item-wrap">
          <a href="/travel-industry.html" className="wsite-menu-item">
            <span>Travel Industry</span>
          </a>
        </li>
        <li id="pg410697422713427165" className="wsite-menu-item-wrap">
          <a href="/about-us.html" className="wsite-menu-item">
            <span>About Us</span>
          </a>
          <div className="wsite-menu-wrap" style={{display: 'none'}}>
            <ul className="wsite-menu">
              <li id="wsite-nav-100258316390562804" className="wsite-menu-subitem-wrap ">
                <a href="/contact.html" className="wsite-menu-subitem">
                  <span className="wsite-menu-title">
                    Contact
                  </span>
                </a>
              </li>
              <li id="wsite-nav-243338099441262481" className="wsite-menu-subitem-wrap ">
                <a href="https://www.tvlcoin.org/uploads/9/1/5/8/91580336/travel_coin_whitepaper.pdf" target="_blank" className="wsite-menu-subitem">
                  <span className="wsite-menu-title">
                    WHITEPAPER
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <li className="mobile-login wsite-menu-item-wrap" />
      <span className="wsite-search">
        <form id="wsite-header-search-form" action="/apps/search" method="get">
          <input type="text" name="q" className="wsite-search-input" autoComplete="off" placeholder="Search" /><span className="wsite-search-button"><span className="wsite-search-button-inner" /></span>
        </form>
      </span>
    </div>

  )
}

export default MobileNav