import React from 'react'
import Footer from '../../components/Footer'

function SiteCanvas(params) {
  return (
    <div className="site-canvas">
      <div className="banner-wrap">
        <div className="wsite-elements wsite-not-footer wsite-header-elements">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-header-section wsite-section-bg-image" style={{backgroundImage: 'url("//cdn2.editmysite.com/images/editor/theme-background/stock/Lake.jpg")', backgroundRepeat: 'no-repeat', backgroundPosition: '50.00% 40.03%', backgroundSize: 'cover', backgroundColor: 'transparent'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="banner">
                    <div className="wsite-section-elements">
                      <h2 className="wsite-content-title" />
                    </div>
                  </div>
                </div>
              </div>
              <div className />
            </div>
          </div>
        </div>
      </div>
      <div className="main-wrap">
        <div id="wsite-content" className="wsite-elements wsite-not-footer">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-background-60">
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <h2 className="wsite-content-title"><strong>PRIVACY POLICY</strong></h2>
                    <div className="paragraph">
                      <font size={1}><strong>Personal Information Protection Policy</strong><br />At Focus Media, we are
                        committed to providing our clients with exceptional service.&nbsp; As providing this service
                        involves the collection, use and disclosure of some personal information about our clients,
                        protecting their personal information is one of our highest priorities.<br />While we have
                        always respected our clients privacy and safeguarded their personal information, we have
                        strengthened our commitment to protecting personal.<br />We will inform our clients of why and
                        how we collect, use and disclose their personal information, obtain their consent where
                        required, and only handle their personal information in a manner that a reasonable person would
                        consider appropriate in the circumstances.<br />This Personal Information Protection Policy
                        outlines the principles and practices we will follow in protecting clients’ personal
                        information.&nbsp; Our privacy commitment includes ensuring the accuracy, confidentiality, and
                        security of our clients’ personal information and allowing our clients to request access
                        to, and correction of, their personal information.<br /><strong>Scope of this
                          Policy&nbsp;</strong><br />This Personal Information Protection Policy applies to Focus
                        Media.<br />This policy also applies to any service providers collecting, using or disclosing
                        personal information on behalf of Focus Media.<br /><u>Definitions</u><br /><strong><em>Personal
                            Information –</em></strong>means information about an identifiable individual or
                        organisation including name, address and phone number, social insurance number, marital status,
                        religion, income, credit history, medical information, education, employment information.
                        &nbsp;Personal information does not include contact information (described
                        below).<br /><strong><em>Contact information&nbsp;</em></strong>– means information that
                        would enable an individual to be contacted at a place of business and includes name, position
                        name or title, business telephone number, business address, business email or business fax
                        number.&nbsp; Contact information is not covered by this policy.<br /><strong><em>Privacy
                            Officer</em></strong>&nbsp;– means the individual designated responsibility for
                        ensuring that Focus Media complies with this policy.<br /><strong>Policy 1 – Collecting
                          Personal Information</strong><br />1.1&nbsp; Unless the purposes for collecting personal
                        information are obvious and the client voluntarily provides his or her personal information for
                        those purposes, we will communicate the purposes for which personal information is being
                        collected, either orally or in writing, before or at the time of collection.<br />1.2&nbsp; We
                        will only collect client information that is necessary to fulfil the following purposes:<br />
                      </font>
                      <ul>
                        <li>
                          <font size={1}>To verify identity;</font>
                        </li>
                        <li>
                          <font size={1}>To identify client preferences;</font>
                        </li>
                        <li>
                          <font size={1}>To deliver requested products and services;</font>
                        </li>
                        <li>
                          <font size={1}>To enrol the client in a program;</font>
                        </li>
                        <li>
                          <font size={1}>To ensure a high standard of service to our clients;</font>
                        </li>
                      </ul>
                      <font size={1}><strong>Policy 2 – Consent</strong><br />2.1&nbsp; We will obtain client
                        consent to collect, use or disclose personal information (except where, as noted below, we are
                        authorized to do so without consent).<br />2.2&nbsp; Consent can be provided in writing,
                        electronically, through an authorized representative or it can be implied where the purpose for
                        collecting using or disclosing the personal information would be considered obvious and the
                        client voluntarily provides personal information for that purpose.<br />2.3&nbsp; Consent may
                        also be implied where a client is given notice and a reasonable opportunity to opt-out of his or
                        her personal information being used for mail-outs, the marketing of new services or products,
                        and the client does not opt-out.<br />2.4&nbsp; Subject to certain exceptions (e.g., the
                        personal information is necessary to provide the service or product, or the withdrawal of
                        consent would frustrate the performance of a legal obligation), clients can withhold or withdraw
                        their consent for Focus Media to use their personal information in certain ways.&nbsp; A
                        client’s decision to withhold or withdraw their consent to certain uses of personal
                        information may restrict our ability to provide a particular service or product.&nbsp; If so, we
                        will explain the situation to assist the client in making the decision.<br />2.5&nbsp; We may
                        collect, use or disclose personal information without the client’s knowledge or consent in
                        the following limited circumstances:<br /></font>
                      <ul>
                        <li>
                          <font size={1}>When the collection, use or disclosure of personal information is permitted or
                            required by law;</font>
                        </li>
                        <li>
                          <font size={1}>In an emergency that threatens an individual's life, health, or personal
                            security;</font>
                        </li>
                        <li>
                          <font size={1}>When the personal information is available from a public source (e.g., a
                            telephone directory);</font>
                        </li>
                        <li>
                          <font size={1}>When we require legal advice from a lawyer;</font>
                        </li>
                        <li>
                          <font size={1}>For the purposes of collecting a debt;</font>
                        </li>
                        <li>
                          <font size={1}>To protect ourselves from fraud;</font>
                        </li>
                        <li>
                          <font size={1}>To investigate an anticipated breach of an agreement or a contravention of law
                          </font>
                        </li>
                      </ul>
                      <font size={1}><strong>Policy 3 – Using and Disclosing Personal
                          Information</strong><br />3.1&nbsp; We will only use or disclose client personal information
                        where necessary to fulfil the purposes identified at the time of collection or for a purpose
                        reasonably related to those purposes such as:<br /></font>
                      <ul>
                        <li>
                          <font size={1}>To conduct client, customer, member surveys in order to enhance the provision
                            of our services;</font>
                        </li>
                        <li>
                          <font size={1}>To contact our clients directly about products and services that may be of
                            interest;</font>
                        </li>
                      </ul>
                      <font size={1}>3.2&nbsp; We will not use or disclose client personal information for any
                        additional purpose unless we obtain consent to do so.<br />3.3&nbsp; We will not sell client
                        lists or personal information to other parties.<br /><strong>Policy 4 – Retaining Personal
                          Information</strong><br />4.1&nbsp; If we use client personal information to make a decision
                        that directly affects the client we will retain that personal information for at least one year
                        so that the client has a reasonable opportunity to request access to it.<br />4.2&nbsp; Subject
                        to policy 4.1, we will retain client personal information only as long as necessary to fulfil
                        the identified purposes or a legal or business purpose.<br /><strong>Policy 5 – Ensuring
                          Accuracy of Personal Information</strong><br />5.1&nbsp; We will make reasonable efforts to
                        ensure that client personal information is accurate and complete where it may be used to make a
                        decision about the client or disclosed to another organisation.<br />5.2&nbsp; Clients may
                        request correction to their personal information in order to ensure its accuracy and
                        completeness.&nbsp; A request to correct personal information must be made in writing and
                        provide sufficient detail to identify the personal information and the correction being
                        sought.<br />5.3&nbsp; If the personal information is demonstrated to be inaccurate or
                        incomplete, we will correct the information as required and send the corrected information to
                        any organization to which we disclosed the personal information in the previous year.&nbsp; If
                        the correction is not made, we will note the clients’ correction request in the
                        file.<br /><strong>Policy 6 – Securing Personal Information</strong><br />6.1&nbsp; We are
                        committed to ensuring the security of client personal information in order to protect it from
                        unauthorized access, collection, use, disclosure, copying, modification or disposal or similar
                        risks.<br />6.2&nbsp; The following security measures will be followed to ensure that client
                        personal information is appropriately protected:<br />the use of locked filing cabinets;
                        physically securing offices where personal information is held; the use of user IDs, passwords,
                        encryption, firewalls; restricting employee access to personal information as appropriate (i.e.,
                        only those that need to know will have access; contractually requiring any service providers to
                        provide comparable security measures.<br />6.3&nbsp; We will use appropriate security measures
                        when destroying client’s personal information such as shredding documents, deleting
                        electronically stored information.<br />6.4&nbsp; We will continually review and update our
                        security policies and controls as technology changes to ensure ongoing personal information
                        security.<br /><strong>Policy 7 – Providing Clients Access to Personal
                          Information</strong><br />&nbsp;7.1&nbsp; Clients have a right to access their personal
                        information, subject to limited exceptions.<br />Exceptions to access that might apply. Some
                        examples include:&nbsp; solicitor-client privilege, disclosure would reveal personal information
                        about another individual, health and safety concerns.<br />7.2&nbsp; A request to access
                        personal information must be made in writing and provide sufficient detail to identify the
                        personal information being sought.<br />7.3&nbsp; Upon request, we will also tell clients how we
                        use their personal information and to whom it has been disclosed if applicable.<br />7.4&nbsp;
                        We will make the requested information available within 30 business days, or provide written
                        notice of an extension where additional time is required to fulfil the request.<br />7.5&nbsp; A
                        minimal fee may be charged for providing access to personal information.&nbsp; Where a fee may
                        apply, we will inform the client of the cost and request further direction from the client on
                        whether or not we should proceed with the request.<br />7.6&nbsp; If a request is refused in
                        full or in part, we will notify the client in writing, providing the reasons for refusal and the
                        recourse available to the client.<br /><strong>Policy 8 – Questions and Complaints:&nbsp;
                          The Role of the Privacy Officer or designated individual</strong><br />8.1&nbsp; The Privacy
                        Officer or designated individual is responsible for ensuring Focus Media’s compliance with
                        this policy.<br />8.2&nbsp; Clients should direct any complaints, concerns or questions
                        regarding Focus Media’s compliance in writing to the Privacy Officer.<br />Contact: you
                        can use the contact form on this website.</font><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className="mini-cart-screen" />
    </div>
  )
}

export default SiteCanvas