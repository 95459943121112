import React from 'react'
import Footer from '../../components/Footer'


function SiteCanvas(props) {
  return (
    <div className="site-canvas">
      <div className="banner-wrap">
        <div className="wsite-elements wsite-not-footer wsite-header-elements">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-header-section wsite-section-bg-image" style={{backgroundImage: 'url(/images/1382121971.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: '50.00% 64.66%', backgroundSize: 'cover', backgroundColor: 'transparent'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="banner">
                    <div className="wsite-section-elements">
                      <h2 className="wsite-content-title" style={{textAlign: 'center'}}>
                        <font size={6}>Digital Marketplace for the Travel Tourism Industry</font>
                      </h2>
                      <div className="wsite-spacer" style={{height: 50}} />
                      <div className="wsite-spacer" style={{height: 50}} />
                      <div className="wsite-spacer" style={{height: 50}} />
                      <div className="wsite-spacer" style={{height: 50}} />
                    </div>
                  </div>
                </div>
              </div>
              <div className />
            </div>
          </div>
        </div>
      </div>
      <div className="main-wrap">
        <div id="wsite-content" className="wsite-elements wsite-not-footer">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-section-bg-gradient wsite-background-17" style={{height: 'auto', background: 'linear-gradient(180deg, #020202 50%, #063970 100%)'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <div id={475557544991780539}>
                      <div>
                        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\n\n                      " }} />
                        <div id="element-e9797295-3264-4757-8d95-7912a68bee14" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                          <div id="container-box">
                            <div className="outer-box hover-">
                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                <div className="content">
                                  <div style={{width: 'auto'}}>
                                    <div />
                                    <div id={816898697335132908}>
                                      <div>
                                        <div id="element-932e61bd-cd0a-4877-9e19-8e2606fa5342" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                          <div id="container-box">
                                            <div className="outer-box hover-">
                                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                <div className="content">
                                                  <div style={{width: 'auto'}}>
                                                    <div />
                                                    <div id={861974585254390223}>
                                                      <div>
                                                        <div id="element-717aeb62-55b6-4b5a-87ab-d7704a2c7d4b" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                          <div className="colored-box">
                                                            <div className="colored-box-content">
                                                              <div style={{width: 'auto'}}>
                                                                <div />
                                                                <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong>Travel Tourism
                                                                    Industry</strong></h2>
                                                                <div>
                                                                  <div className="wsite-multicol">
                                                                    <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                                      <table className="wsite-multicol-table">
                                                                        <tbody className="wsite-multicol-tbody">
                                                                          <tr className="wsite-multicol-tr">
                                                                            <td className="wsite-multicol-col" style={{width: '50%', padding: '0 15px'}}>
                                                                              <div className="paragraph">
                                                                                <font color="#000000">Globally, travel
                                                                                  and tourism's direct contribution to
                                                                                  GDP&nbsp;was approximately 4.7
                                                                                  trillion U.S. dollars in 2020.<font size={1}>1<br />​</font>
                                                                                  <br />Travel &amp; Tourism GDP
                                                                                  accounted for 10.4 % of global economy
                                                                                  GDP in 2019.<font size={1}>2</font>
                                                                                  <br /><br />Over the last decade,
                                                                                  Travel Tourism has been an important
                                                                                  driver for job creation.&nbsp;&nbsp;In
                                                                                  2019, the sector maintained 334
                                                                                  million jobs (10.6% of all jobs) and
                                                                                  was responsible for creating 1 in 4 of
                                                                                  all net new jobs across the world
                                                                                  between 2014 – 2019.<font size={1}>3</font><br /><br />The
                                                                                  travel and tourism Industry shows no
                                                                                  sign of slowing down
                                                                                  with&nbsp;<br />digitalisation
                                                                                  changing the way people live, work,
                                                                                  and travel, and has opened up new
                                                                                  opportunities for tourism businesses
                                                                                  to compete in global
                                                                                  markets.&nbsp;&nbsp;New methods of
                                                                                  arranging, booking and paying for
                                                                                  their travel.<br /><br />New
                                                                                  technology embraces the upward trend
                                                                                  that is both scalable and secure with
                                                                                  growth.</font>
                                                                              </div>
                                                                            </td>
                                                                            <td className="wsite-multicol-col" style={{width: '50%', padding: '0 15px'}}>
                                                                              <div id={306025512776805132}>
                                                                                <div>
                                                                                  <div id="element-1e10112b-c60c-4c7f-a8c6-312d4d5f1532" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                                    <div className="colored-box">
                                                                                      <div className="colored-box-content">
                                                                                        <div style={{width: 'auto'}}>
                                                                                          <div />
                                                                                          <div className="paragraph" style={{textAlign: 'center'}}>
                                                                                            <strong>TOURISM CONTRIBUTION
                                                                                              TO WORLD GDP IN
                                                                                              $B</strong></div>
                                                                                          <div>
                                                                                            <div className="wsite-image wsite-image-border-medium " style={{paddingTop: 10, paddingBottom: 10, marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                                                                                              <a>
                                                                                                <img src="/images/screenshot-2021-11-02-at-14-24-55_orig.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                                              </a>
                                                                                              <div style={{display: 'block', fontSize: '90%'}}>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div style={{clear: 'both'}} />
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                          </tr>
                                                                        </tbody>
                                                                      </table>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div>
                                                                  <div className="wsite-multicol">
                                                                    <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                                      <table className="wsite-multicol-table">
                                                                        <tbody className="wsite-multicol-tbody">
                                                                          <tr className="wsite-multicol-tr">
                                                                            <td className="wsite-multicol-col" style={{width: '50%', padding: '0 15px'}}>
                                                                              <div id={432677134610889945}>
                                                                                <div>
                                                                                  <div id="element-4fbc23e3-d21c-4488-8bc1-8fe7a37adb1c" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                                    <div className="colored-box">
                                                                                      <div className="colored-box-content">
                                                                                        <div style={{width: 'auto'}}>
                                                                                          <div />
                                                                                          <div className="paragraph" style={{textAlign: 'center'}}>
                                                                                            <span style={{fontWeight: 'bold'}}>
                                                                                              <font color="#000000">
                                                                                                TOTAL CONTRIBUTUTION OF
                                                                                                TRAVEL &amp;
                                                                                                TOURISM<br />​TO
                                                                                                GLOBAL ECONOMY</font>
                                                                                              <br />
                                                                                              <font color="#0f6fc6">
                                                                                                ​</font>
                                                                                            </span><span>4.67tr USD
                                                                                              (9.17tr USD in
                                                                                              2019)</span></div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div style={{clear: 'both'}} />
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                            <td className="wsite-multicol-col" style={{width: '50%', padding: '0 15px'}}>
                                                                              <div id={891134498471068618}>
                                                                                <div>
                                                                                  <div id="element-e6e82afe-dd2b-4618-851f-f28fcdb99f71" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                                    <div className="colored-box">
                                                                                      <div className="colored-box-content">
                                                                                        <div style={{width: 'auto'}}>
                                                                                          <div />
                                                                                          <div className="paragraph" style={{textAlign: 'center'}}>
                                                                                            <span style={{fontWeight: 'bold'}}>
                                                                                              <font color="#0d0d0d">
                                                                                                NUMBER OF INTERNATIONAL
                                                                                                TOURIST ARRIVALS
                                                                                                WORLDWIDE</font>
                                                                                            </span><br /><span>399m
                                                                                              (1.47B in 2019)</span>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div style={{clear: 'both'}} />
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                          </tr>
                                                                        </tbody>
                                                                      </table>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{clear: 'both'}} />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                        </div>
                                        <div style={{clear: 'both'}} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                        </div>
                        <div style={{clear: 'both'}} />
                      </div>
                    </div>
                    <div className="paragraph" style={{textAlign: 'left'}}>
                      <font color="#a1a1a1">
                        <font size={1}>1&nbsp;&nbsp;www.wttc.org&nbsp;This figure saw a huge decline over the previous
                          year as a result of the coronavirus (COVID-19) pandemic which caused travel disruption across
                          the world.&nbsp;<br />
                          <font>2&nbsp;www.statista.com<br />3</font>
                        </font>
                        <font size={1}>&nbsp;www.wttc.org Global Economic Impact Report 2021</font>
                      </font><br /><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className="mini-cart-screen" />
    </div>
  )
}

export default SiteCanvas