import React, { useEffect } from 'react'
import SiteCanvas from './SiteCanvas'
import Header from '../../components/Header'
import MobileNav from '../../components/MobileNav'

function VirtualTravel(props) {

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [])

  return(
    <div className="wrapper">
      <Header />
      <SiteCanvas />
      <MobileNav />
    </div>
  )
}

export default VirtualTravel