import React from 'react'
import Footer from '../../components/Footer'

function SiteCanvas(params) {
  return (
    <div className="site-canvas">
    <div className="banner-wrap">
      <div className="wsite-elements wsite-not-footer wsite-header-elements">
        <div className="wsite-section-wrap">
          <div className="wsite-section wsite-header-section wsite-section-bg-image wsite-section-effect-parallax" style={{height: '1003px', verticalAlign: 'middle', backgroundImage: 'url(/images/1967098395.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundColor: 'transparent', backgroundAttachment: 'fixed'}}>
            <div className="wsite-section-content">
              <div className="container">
                <div className="banner">
                  <div className="wsite-section-elements">
                    <div>
                      <div className="wsite-multicol">
                        <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                          <table className="wsite-multicol-table">
                            <tbody className="wsite-multicol-tbody">
                              <tr className="wsite-multicol-tr">
                                <td className="wsite-multicol-col" style={{width: '80.560747663551%', padding: '0 15px'}}>
                                  <div className="wsite-spacer" style={{height: '10px'}} />
                                  <h2 className="wsite-content-title"> TRAVEL COIN&nbsp;</h2>
                                </td>
                                <td className="wsite-multicol-col" style={{width: '19.439252336449%', padding: '0 15px'}}>
                                  <div className="wsite-spacer" style={{height: '50px'}} />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="wsite-multicol">
                        <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                          <table className="wsite-multicol-table">
                            <tbody className="wsite-multicol-tbody">
                              <tr className="wsite-multicol-tr">
                                <td className="wsite-multicol-col" style={{width: '80.841121495327%', padding: '0 15px'}}>
                                  <div className="paragraph"><strong>
                                      <font size={6}>Digital Currency for the Travel &amp; Tourism Industry</font>
                                    </strong></div>
                                </td>
                                <td className="wsite-multicol-col" style={{width: '19.158878504673%', padding: '0 15px'}}>
                                  <div className="wsite-spacer" style={{height: '50px'}} />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="wsite-multicol">
                        <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                          <table className="wsite-multicol-table">
                            <tbody className="wsite-multicol-tbody">
                              <tr className="wsite-multicol-tr">
                                <td className="wsite-multicol-col" style={{width: '20.324373088533%', padding: '0 15px'}}>
                                  <div className="wsite-spacer" style={{height: '50px'}} />
                                </td>
                                <td className="wsite-multicol-col" style={{width: '79.675626911467%', padding: '0 15px'}}>
                                  <div className="wsite-spacer" style={{height: '48px'}} />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="main-wrap">
      <div id="wsite-content" className="wsite-elements wsite-not-footer">
        <div className="wsite-section-wrap">
          <div className="wsite-section wsite-body-section wsite-background-57" style={{height: 'auto'}}>
            <div className="wsite-section-content">
              <div className="container">
                <div className="wsite-section-elements">
                  <div>
                    <div className="wsite-image wsite-image-border-none " style={{paddingTop: '10px', paddingBottom: '10px', marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                      <a>
                        <img src="/images/tvl-coin-logo.png" alt="Picture" style={{width: 174, maxWidth: '100%'}} />
                      </a>
                      <div style={{display: 'block', fontSize: '90%'}} />
                    </div>
                  </div>
                  <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong> TRAVEL COIN </strong></h2>
                  <div className="paragraph" style={{textAlign: 'center'}}><strong>UNIVERSAL DIGITAL CURRENCY BRINGING
                      TOGETHER THE TRAVEL TRADE INDUSTRY WITH CONSUMERS</strong></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wsite-section-wrap">
          <div className="wsite-section wsite-body-section wsite-section-bg-image wsite-background-3" style={{height: '1289px', backgroundImage: 'url(/images/1802692359.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: '51.41% 0.00%', backgroundSize: 'cover', backgroundColor: 'transparent'}}>
            <div className="wsite-section-content">
              <div className="container">
                <div className="wsite-section-elements">
                  <div id={221704412584153101}>
                    <div>
                      <div id="element-f321ce01-2b65-41de-a93e-c2939f53a372" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                        <div id="container-box">
                          <div className="outer-box hover-">
                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                              <div className="content">
                                <div style={{width: 'auto'}}>
                                  <div />
                                  <div id={331602612630956824}>
                                    <div>
                                      <div id="element-b908225f-eb7e-4324-a065-e604aceaa9ad" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                        <div style={{height: 'calc(2*1px)', overflow: 'hidden', width: '100%'}} />
                                        <div id="container-box">
                                          <div className="outer-box hover-">
                                            <div className="color-box  border-1 borderSides-All shadow-1 shadowVert- shadowHor-">
                                              <div className="content">
                                                <div style={{width: 'auto'}}>
                                                  <div />
                                                  <div id={314427697742510785}>
                                                    <div>
                                                      <div id="element-be722ff0-ee00-4157-9c55-464bcc82c982" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                        <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                        <div id="container-box">
                                                          <div className="outer-box hover-">
                                                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                              <div className="content">
                                                                <div style={{width: 'auto'}}>
                                                                  <div />
                                                                  <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong>
                                                                      <font size={6} color="#ffffff">Discover the
                                                                        World with Travel Coin </font>
                                                                    </strong></h2>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                  <div className="wsite-spacer" style={{height: '17px'}} />
                                                  <div>
                                                    <div className="wsite-multicol">
                                                      <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                        <table className="wsite-multicol-table">
                                                          <tbody className="wsite-multicol-tbody">
                                                            <tr className="wsite-multicol-tr">
                                                              <td className="wsite-multicol-col" style={{width: '75.614366729679%', padding: '0 15px'}}>
                                                                <div className="paragraph">Since January 2010 we have been
                                                                  working on publishing digital assets for the tourism
                                                                  and travel industry which included using 3d design
                                                                  technologies for the creation of international
                                                                  tourism destinations in the fields of ecotourism,
                                                                  luxury, business travel, history, culture and
                                                                  ecology.<br />In 2013 we have looked into
                                                                  understanding new ways of buying and selling,
                                                                  exchanging these created artworks through a
                                                                  digitalised open payment ecosystem.
                                                                  &nbsp;<br />​<br />A new universal token
                                                                  concept was created that would benefit, work and be
                                                                  accepted by consumers, businesses in the travel
                                                                  industry. &nbsp; However the actual solution
                                                                  couldn’t be executed until now.
                                                                  &nbsp;<br />​With current knowledge of the
                                                                  cryptocurrency ecosystem and adoption of new
                                                                  blockchain technologies, we can now integrate these
                                                                  solutions within the global travel industry,
                                                                  creating a good reliable database of clients that
                                                                  benefit businesses globally for a new sustainable
                                                                  future which is scalable, secure, fast and engaging.
                                                                  &nbsp;</div>
                                                              </td>
                                                              <td className="wsite-multicol-col" style={{width: '24.385633270321%', padding: '0 15px'}}>
                                                                <div id={262078054863486153}>
                                                                  <div>
                                                                    <div id="element-6b43293e-35d8-4bad-bc14-00b165cfd3e0" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                                      <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                      </div>
                                                                      <div id="container-box">
                                                                        <div className="outer-box hover-">
                                                                          <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                                            <div className="content">
                                                                              <div style={{width: 'auto'}}>
                                                                                <div />
                                                                                <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong>
                                                                                    <font color="#d29502" size={5}><a href="https://tvlcoin.org#ajsection-BUY">HOW
                                                                                        TO BUY</a></font>
                                                                                  </strong></h2>
                                                                                <div id={239710621714060315}>
                                                                                  <div>
                                                                                    <div id="element-468110da-62d6-4f20-8198-b403c30ede67" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                                      <div className="colored-box">
                                                                                        <div className="colored-box-content">
                                                                                          <div style={{width: 'auto'}}>
                                                                                            <div />
                                                                                            <div id={431118090504661024}>
                                                                                              <div>
                                                                                                <div id="element-2c179077-e48a-4494-ac68-f440a358ee13" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                                                  <div className="colored-box">
                                                                                                    <div className="colored-box-content">
                                                                                                      <div style={{width: 'auto'}}>
                                                                                                        <div />
                                                                                                        <div>
                                                                                                          <div className="wsite-image wsite-image-border-none " style={{paddingTop: '10px', paddingBottom: '10px', marginLeft: '0px', marginRight: '0px', textAlign: 'center'}}>
                                                                                                            <a href="https://tvlcoin.org#ajsection-BUY">
                                                                                                              <img src="/images/screenshot-2021-11-09-at-17-13-09.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                                                            </a>
                                                                                                            <div style={{display: 'block', fontSize: '90%'}}>
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    </div>
                                                                                                  </div>
                                                                                                </div>
                                                                                                <div style={{clear: 'both'}}>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                    <div style={{clear: 'both'}} />
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                      </div>
                                                                    </div>
                                                                    <div style={{clear: 'both'}} />
                                                                  </div>
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="paragraph" style={{textAlign: 'left'}}>
                                                    <font color="#2a2a2a"><strong><br />​TRAVEL COIN </strong>
                                                       has the potential to revolutionise the way that bookings can
                                                      be&nbsp;made in the travel and tourism industry.<br />​
                                                    </font><br /><strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL
                                                      COIN</strong>
                                                    <font color="#2a2a2a"> embraces the latest technologies that will
                                                      provide a fulfilled&nbsp;experience for consumers and provide a
                                                      variety of investment and&nbsp;consumable options in the digital
                                                      online metaverse.</font><br /><br /><strong style={{color: 'rgb(42, 42, 42)'}}> TRAVEL COIN </strong>
                                                    <font color="#2a2a2a"> summarises some of the existing problems
                                                      and&nbsp;proposes a viable solution.</font><br /><br /><strong style={{color: 'rgb(42, 42, 42)'}}> TRAVEL COIN </strong>
                                                    <font color="#2a2a2a"> demonstrates how leveraging
                                                      of&nbsp;blockchain technology can be used to create a relevant
                                                      ecosystem by&nbsp;a single global token which can revolutionise
                                                      the industry in a&nbsp;sustainable way which is mutually
                                                      beneficial for all relevant travel and&nbsp;tourism trade
                                                      industry stakeholders.</font>
                                                  </div>
                                                  <div className="paragraph"><strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL
                                                      COIN </strong>
                                                      <span style={{color: 'rgb(42, 42, 42)'}}>
                                                       is the first ecosystem built on the BSC blockchain that will combine travel destinations, NFT marketplace, stakeholders from the travel and tourism trade industry, and the value of digital collectables, transforming it into a global digital business marketplace.
                                                    </span><br />
                                                      
                                                    <span style={{color: 'rgb(42, 42, 42)'}}>By integrating </span>
                                                    <strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL COIN </strong><span style={{color: 'rgb(42, 42, 42)'}}> 
                                                      with BSC - a blockchain-based centralised financial system, 
                                                    </span><strong style={{color: 'rgb(42, 42, 42)'}}> TRAVEL
                                                      COIN</strong><span style={{color: 'rgb(42, 42, 42)'}}> will become a highly desirable, world travel experience, but also a tangible investment.</span></div>

                                                  <div className="paragraph"><strong> TRAVEL COIN logo
                                                      creation</strong><br />Hermes was known as the Greek god of
                                                    travel and trade.&nbsp; His golden wings are used in the logo
                                                    because Hermes was considered to be the protector of human
                                                    travellers and merchants.<br />In addition the top part element of
                                                    the logo, the crescent feature, represents the symbol for Khonsu,
                                                    the Egyptian god of the moon and time.&nbsp; His name means the
                                                    word travel and was considered to be the creator of the universe
                                                    and traveller between worlds.</div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{height: 'calc(2*1px)', overflow: 'hidden', width: '100%'}} />
                                      </div>
                                      <div style={{clear: 'both'}} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                      </div>
                      <div style={{clear: 'both'}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wsite-section-wrap">
          <div className="wsite-section wsite-body-section wsite-section-bg-image wsite-section-effect-parallax wsite-background-12" style={{height: '737px', backgroundImage: 'url(/images/2144339732.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundColor: 'transparent', backgroundAttachment: 'fixed'}}>
            <div className="wsite-section-content">
              <div className="container">
                <div className="wsite-section-elements">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wsite-section-wrap">
          <div className="wsite-section wsite-body-section wsite-section-bg-image wsite-background-7" style={{height: '496px', verticalAlign: 'middle', backgroundImage: 'url(/images/581630007.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundColor: 'transparent'}}>
            <div className="wsite-section-content">
              <div className="container">
                <div className="wsite-section-elements">
                  <div id={374692564519909028}>
                    <div>
                      <div id="element-ec8de122-60c0-447a-bb47-062c256cfc17" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                        <div id="container-box">
                          <div className="outer-box hover-">
                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                              <div className="content">
                                <div style={{width: 'auto'}}>
                                  <div />
                                  <div id={878748849560033453}>
                                    <div>
                                      <div id="element-b369774a-b6a6-41bf-9506-816bd71c7855" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                        <div style={{height: 'calc(2*1px)', overflow: 'hidden', width: '100%'}} />
                                        <div id="container-box">
                                          <div className="outer-box hover-">
                                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                              <div className="content">
                                                <div style={{width: 'auto'}}>
                                                  <div />
                                                  <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong>
                                                      <font size={6} color="#2a2a2a"> TRAVEL COIN ECOSYSTEM</font>
                                                    </strong></h2>
                                                  <div className="paragraph" style={{textAlign: 'center'}}>
                                                    <font color="#2a2a2a">One of the key features of <strong>TRAVEL
                                                        COIN</strong> is the large ecosystem to be built out which is
                                                      inclusive of the <strong>TRAVEL COIN</strong> token $TVL, NFTs,
                                                      wallet, Farming, Swap, stakeholder pools and the NFT
                                                      marketplace.</font>
                                                  </div>
                                                  <div id={265514212438892449}>
                                                    <div>
                                                      <div id="element-3b702d4f-a2d5-4cf1-a808-db7c6b5b20e1" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                        <div style={{height: 'calc(10*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                        <div id="container-box">
                                                          <div className="outer-box hover-">
                                                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                              <div className="content">
                                                                <div style={{width: 'auto'}}>
                                                                  <div />
                                                                  <div id={300448490525268919}>
                                                                    <div>
                                                                      <div id="element-4cb9ecc2-5ea8-4319-949c-7526376d5e33" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                        <div className="colored-box">
                                                                          <div className="colored-box-content">
                                                                            <div style={{width: 'auto'}}>
                                                                              <div />
                                                                              <div>
                                                                                <div className="wsite-image wsite-image-border-none " style={{paddingTop: '10px', paddingBottom: '10px', marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                                                                                  <a>
                                                                                    <img src="/images/tvl-coin-logo_orig.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                                  </a>
                                                                                  <div style={{display: 'block', fontSize: '90%'}}>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                              <div className="paragraph" style={{textAlign: 'center'}}><strong style={{color: 'rgb(63, 63, 63)'}}>

                                                                                  <font color="#2a2a2a" size={4}>
                                                                                    TRAVEL COIN <br /> ​TOKEN
                                                                                    $TVL<br />​</font>
                                                                                </strong><br /><span style={{color: 'rgb(42, 42, 42)'}}>
                                                                                <strong>TRAVEL COIN</strong> token $TVL are utility tokens that have real value and can be used for all transactions in the <strong>TRAVEL COIN</strong> ecosystem. The intention is for the launch to be on the BSC blockchain.</span>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{clear: 'both'}} />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{height: 'calc(10*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                  <div id={668832596464488765}>
                                                    <div>
                                                      <div id="element-64b9928b-9228-4fca-addb-9efe7a79b5b8" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                        <div style={{height: 'calc(10*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                        <div id="container-box">
                                                          <div className="outer-box hover-">
                                                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                              <div className="content">
                                                                <div style={{width: 'auto'}}>
                                                                  <div />
                                                                  <div id={202257608124733967}>
                                                                    <div>
                                                                      <div id="element-8fc9af06-0b2c-4452-9cbc-d483dc9845e2" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                        <div className="colored-box">
                                                                          <div className="colored-box-content">
                                                                            <div style={{width: 'auto'}}>
                                                                              <div />
                                                                              <div>
                                                                                <div className="wsite-image wsite-image-border-none " style={{paddingTop: '10px', paddingBottom: '10px', marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                                                                                  <a>
                                                                                    <img src="/images/tvl-coin-logo_orig.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                                  </a>
                                                                                  <div style={{display: 'block', fontSize: '90%'}}>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                              <div className="paragraph" style={{textAlign: 'center'}}>
                                                                                <font size={4}><span style={{color: 'rgb(42, 42, 42)', fontWeight: 'bold'}}>NFT</span>
                                                                                </font><br /><span style={{color: 'rgb(42, 42, 42)', fontWeight: 'bold'}}>
                                                                                  <font size={4}>​MARKETPLACE
                                                                                  </font><br /><br />​
                                                                                </span><span style={{color: 'rgb(42, 42, 42)'}}>NFT
                                                                                  marketplace for buying, selling and
                                                                                  trading of specific travel and
                                                                                  tourism NFTs.</span>
                                                                                <font size={3} style={{color: 'rgb(42, 42, 42)'}}>&nbsp;
                                                                                </font><span style={{color: 'rgb(42, 42, 42)'}}>The
                                                                                  marketplace will also include a
                                                                                  minting tool that will let users
                                                                                  create their own NFTs from images,
                                                                                  videos &amp; other
                                                                                  data.<br /></span>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{clear: 'both'}} />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{height: 'calc(10*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                  <div id={773414330739798644}>
                                                    <div>
                                                      <div id="element-5ec518a5-1f1d-46c8-aa5e-010664c22c5e" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                        <div style={{height: 'calc(10*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                        <div id="container-box">
                                                          <div className="outer-box hover-">
                                                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                              <div className="content">
                                                                <div style={{width: 'auto'}}>
                                                                  <div />
                                                                  <div id={267670803318655891}>
                                                                    <div>
                                                                      <div id="element-4ce21975-c746-4208-a83b-a30743a912bb" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                        <div className="colored-box">
                                                                          <div className="colored-box-content">
                                                                            <div style={{width: 'auto'}}>
                                                                              <div />
                                                                              <div>
                                                                                <div className="wsite-image wsite-image-border-none " style={{paddingTop: '10px', paddingBottom: '10px', marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                                                                                  <a>
                                                                                    <img src="/images/tvl-coin-logo_orig.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                                  </a>
                                                                                  <div style={{display: 'block', fontSize: '90%'}}>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                              <div className="paragraph" style={{textAlign: 'center'}}>
                                                                                <font size={4}><span style={{color: 'rgb(42, 42, 42)', fontWeight: 'bold'}}>TRAVEL
                                                                                    COIN</span><br /><span style={{color: 'rgb(42, 42, 42)', fontWeight: 'bold'}}>NFTs</span>
                                                                                </font><br /><br />​<span style={{color: 'rgb(42, 42, 42)'}}><strong>TRAVEL
                                                                                    COIN</strong>&nbsp;will also have
                                                                                  its own NFT collections as well as
                                                                                  collaborations with other travel and
                                                                                  tourism companies and institutions.
                                                                                  NFTs for sale are from stakeholders,
                                                                                  destination partners or users and
                                                                                  they are bought
                                                                                  with&nbsp;</span><strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL
                                                                                  COIN</strong><span style={{color: 'rgb(42, 42, 42)'}}>&nbsp;tokens
                                                                                  $TVL</span>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{clear: 'both'}} />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{height: 'calc(10*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                  <div id={686006136902042752}>
                                                    <div>
                                                      <div id="element-e2e702a1-4974-4dfd-9452-a39c1c0becd8" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                        <div style={{height: 'calc(10*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                        <div id="container-box">
                                                          <div className="outer-box hover-">
                                                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                              <div className="content">
                                                                <div style={{width: 'auto'}}>
                                                                  <div />
                                                                  <div id={511498078861280075}>
                                                                    <div>
                                                                      <div id="element-bfaeeef1-0a8b-4075-b6e2-3f6ff1d33fd3" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                        <div className="colored-box">
                                                                          <div className="colored-box-content">
                                                                            <div style={{width: 'auto'}}>
                                                                              <div />
                                                                              <div>
                                                                                <div className="wsite-image wsite-image-border-none " style={{paddingTop: '10px', paddingBottom: '10px', marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                                                                                  <a>
                                                                                    <img src="/images/tvl-coin-logo_orig.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                                  </a>
                                                                                  <div style={{display: 'block', fontSize: '90%'}}>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                              <div className="paragraph" style={{textAlign: 'center'}}><span style={{color: 'rgb(42, 42, 42)', fontWeight: 'bold'}}>
                                                                                  <font size={4}>VIRTUAL TRAVEL
                                                                                    METAVERSE<br /><br />​
                                                                                  </font>
                                                                                </span><span style={{color: 'rgb(42, 42, 42)'}}>A
                                                                                  Metaverse built that will include
                                                                                  NFT keys.&nbsp;&nbsp;These keys will
                                                                                  give VIP access to certain areas in
                                                                                  the&nbsp;</span><strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL
                                                                                  COIN</strong><span style={{color: 'rgb(42, 42, 42)'}}>&nbsp;virtual
                                                                                  world that will include virtual
                                                                                  experiences of different unique
                                                                                  destinations around the world,
                                                                                  videos and be immersed in AI tech as
                                                                                  if you are on location.</span></div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{clear: 'both'}} />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{height: 'calc(10*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                  <div id={132739647335938845}>
                                                    <div>
                                                      <div id="element-b49466af-676a-4075-9fe2-9158eaf85f3d" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                        <div style={{height: 'calc(10*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                        <div id="container-box">
                                                          <div className="outer-box hover-">
                                                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                              <div className="content">
                                                                <div style={{width: 'auto'}}>
                                                                  <div />
                                                                  <div id={531306555652362729}>
                                                                    <div>
                                                                      <div id="element-afe7f2cd-5f50-4b67-a00b-2ce176fb511e" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                        <div className="colored-box">
                                                                          <div className="colored-box-content">
                                                                            <div style={{width: 'auto'}}>
                                                                              <div />
                                                                              <div>
                                                                                <div className="wsite-image wsite-image-border-none " style={{paddingTop: '10px', paddingBottom: '10px', marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                                                                                  <a>
                                                                                    <img src="/images/tvl-coin-logo_orig.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                                  </a>
                                                                                  <div style={{display: 'block', fontSize: '90%'}}>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                              <div className="paragraph" style={{textAlign: 'center'}}>
                                                                                <font size={4}><span style={{color: 'rgb(42, 42, 42)', fontWeight: 'bold'}}>TRAVEL
                                                                                    COIN</span><br /><span style={{color: 'rgb(42, 42, 42)', fontWeight: 'bold'}}>​SWAP</span>
                                                                                </font><br /><br />
                                                                                <font color="#2a2a2a">
                                                                                  A Decentralised Exchange that will be compatible with wallets that support BSC. The exchange will serve as a window for other projects to be built on the BSC smart contracts, providing <strong>TRAVEL COIN</strong> tokens $TVL a prominent spot in the ecosystem. 
                                                                                </font>
                                                                                
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{clear: 'both'}} />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{height: 'calc(10*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                  <div id={579218143498890585}>
                                                    <div>
                                                      <div id="element-3f51b0b7-670d-4571-a35f-7860e4fee977" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                        <div style={{height: 'calc(10*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                        <div id="container-box">
                                                          <div className="outer-box hover-">
                                                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                              <div className="content">
                                                                <div style={{width: 'auto'}}>
                                                                  <div />
                                                                  <div id={900889838859406010}>
                                                                    <div>
                                                                      <div id="element-cb34d532-63a6-46c2-9cc1-959f5d385cb0" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                        <div className="colored-box">
                                                                          <div className="colored-box-content">
                                                                            <div style={{width: 'auto'}}>
                                                                              <div />
                                                                              <div>
                                                                                <div className="wsite-image wsite-image-border-none " style={{paddingTop: '10px', paddingBottom: '10px', marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                                                                                  <a>
                                                                                    <img src="/images/tvl-coin-logo_orig.png" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                                  </a>
                                                                                  <div style={{display: 'block', fontSize: '90%'}}>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                              <div className="paragraph" style={{textAlign: 'center'}}>
                                                                                <font size={4}><span style={{color: 'rgb(42, 42, 42)', fontWeight: 'bold'}}>TRAVEL
                                                                                    COIN</span><br /><span style={{color: 'rgb(42, 42, 42)', fontWeight: 'bold'}}>WALLET<br /><br />​</span>
                                                                                </font><span style={{color: 'rgb(42, 42, 42)'}}>An API
                                                                                  secure place to secure
                                                                                  your&nbsp;</span><strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL
                                                                                  COIN</strong><span style={{color: 'rgb(42, 42, 42)'}}>&nbsp;tokens
                                                                                  $TVL and earn rewards.</span><span style={{color: 'rgb(63, 63, 63)'}}>&nbsp;&nbsp;​The
                                                                                  cryptocurrency wallet will hold the
                                                                                  tokens for consumers to invest in,
                                                                                  utilise and pay with.<br /></span>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{clear: 'both'}} />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{height: 'calc(10*1px)', overflow: 'hidden', width: '100%'}}>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{height: 'calc(2*1px)', overflow: 'hidden', width: '100%'}} />
                                      </div>
                                      <div style={{clear: 'both'}} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                      </div>
                      <div style={{clear: 'both'}} />
                    </div>
                  </div>
                  <div id={913270429613908970}>
                    <div>
                      <div id="element-cc5c83aa-6172-4329-8d9d-b1bdb6b0f3e5" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                        <div id="container-box">
                          <div className="outer-box hover-">
                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                              <div className="content">
                                <div style={{width: 'auto'}}>
                                  <div />
                                  <div id={137737240515387707}>
                                    <div>
                                      <div id="element-95af4265-960e-462f-ac78-0686d78252d6" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                        <div id="container-box">
                                          <div className="outer-box hover-">
                                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                              <div className="content">
                                                <div style={{width: 'auto'}}>
                                                  <div />
                                                  <div id={414590377188409684}>
                                                    <div>
                                                      <div id="element-5232f8a0-bfa0-46f8-92f5-a539143dc9e7" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                        <div className="colored-box">
                                                          <div className="colored-box-content">
                                                            <div style={{width: 'auto'}}>
                                                              <div />
                                                              <div id={760699376771326385}>
                                                                <div>
                                                                  <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" }} />
                                                                  <div id="element-346b4462-3ed0-4cdd-a62d-492146cb98c4" data-platform-element-id="325075642728609279-1.0.0" className="platform-element-contents">
                                                                    <div className="aj-anchor" id="BUY" data-offset={-100} data-scrollspeed={1200} data-animtype="swing">
                                                                    </div>
                                                                  </div>
                                                                  <div style={{clear: 'both'}} />
                                                                </div>
                                                              </div>
                                                              <div>
                                                                <div className="wsite-image wsite-image-border-none " style={{paddingTop: '10px', paddingBottom: '10px', marginLeft: '0px', marginRight: '0px', textAlign: 'center'}}>
                                                                  <a>
                                                                    <img src="/images/tvl-coin-logo.png" alt="Picture" style={{width: 203, maxWidth: '100%'}} />
                                                                  </a>
                                                                  <div style={{display: 'block', fontSize: '90%'}} />
                                                                </div>
                                                              </div>
                                                              <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong>
                                                                  <font size={6}> TRAVEL COIN <br />TOKEN -&nbsp;$TVL
                                                                  </font>
                                                                </strong>&nbsp;</h2>
                                                              <div className="wsite-spacer" style={{height: '13px'}} />
                                                              <div id={428653583836908761}>
                                                                <div>
                                                                  <div id="element-b8235a18-237b-443b-b3d8-569a7845fd3d" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                                    <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                    </div>
                                                                    <div id="container-box">
                                                                      <div className="outer-box hover-">
                                                                        <div className="color-box  border- borderSides-All shadow- shadowVert- shadowHor-">
                                                                          <div className="content">
                                                                            <div style={{width: 'auto'}}>
                                                                              <div />
                                                                              <div className="wsite-spacer" style={{height: '26px'}} />
                                                                              <div className="paragraph" style={{textAlign: 'center'}}><strong>
                                                                                  <font color="#d29502" size={5}>HOW
                                                                                    TO BUY</font>
                                                                                </strong></div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                    </div>
                                                                  </div>
                                                                  <div style={{clear: 'both'}} />
                                                                </div>
                                                              </div>
                                                              <div className="wsite-spacer" style={{height: '16px'}} />
                                                              <div className="paragraph"><strong> TRAVEL COIN </strong>
                                                                token $TVL will be soon available for purchase.
                                                              <br /><strong> TRAVEL COIN </strong> 
                                                                $TVL token are best purchased and sold through a decentralised exchange (DEX) and run on the BSC blockchain.
                                                                The DEX will allow you to exclusively exchange cryptocurrency tokens such as BNB native tokens for the <strong>TRAVEL COIN</strong> $TVL token.
                                                              <br /><br />
                                                                $TVL token will be made available through other growing BSC DEXs.  The list of DEXs which supports the $TVL token will be provided soon for a launch.
                                                              <br />​<br />

                                                                <em><strong>Contract address: coming soon</strong></em><br /><br />
                                                              </div>
                                                              <div id={823067534257586216}>
                                                                <div>
                                                                  <div id="element-2bebd4af-f074-442f-a2d2-20ce85bf4094" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                                    <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                    </div>
                                                                    <div id="container-box">
                                                                      <div className="outer-box hover-">
                                                                        <div className="color-box  border- borderSides-All shadow- shadowVert- shadowHor-">
                                                                          <div className="content">
                                                                            <div style={{width: 'auto'}}>
                                                                              <div />
                                                                              <div className="wsite-spacer" style={{height: '22px'}} />
                                                                              <div className="paragraph" style={{textAlign: 'center'}}><strong>
                                                                                  <font color="#d29502" size={5}>QUICK START GUIDE</font>
                                                                                </strong></div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                    </div>
                                                                  </div>
                                                                  <div style={{clear: 'both'}} />
                                                                </div>
                                                              </div>
                                                              <div className="wsite-spacer" style={{height: '18px'}} />
                                                              <div className="paragraph">
                                                                <strong>1. Create METAMASK or similar soft wallet</strong>
                                                                <br />Create METAMASK wallet or similar using either desktop computer or an iOS/Android mobile device. Using this option will allow you to receive/buy with BNB or BSC native tokens from the DEX and swap and receive $TVL tokens.<br /><br />
                                                                <strong>2. Send </strong><strong style={{color: 'rgb(63, 63, 63)'}}></strong><strong> BSC or BNB to your wallet</strong><br />
                                                                You can also buy BNB or BSC directly on your wallet, but is expensive. Preferred choice is to open an account with Binance and send BNB/BSC to your wallet. <br /><br />
                                                                <strong>3. Buy DEX BNB native token</strong><br />
                                                                  Once the DEX BNB native token has been purchased with BSC you are then ready to swap and receive $TVL tokens.
                                                                <br /><br /><strong>4. Swap DEX BNB native Token for $TVL token</strong><br />
                                                                Using the DEX platform you can swap your purchased DEX BNB native tokens for $TVL token.<br /><br /><strong><em>List of BSC DEXs partners coming soon</em></strong></div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                      </div>
                                      <div style={{clear: 'both'}} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                      </div>
                      <div style={{clear: 'both'}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wsite-section-wrap">
          <div className="wsite-section wsite-body-section wsite-section-bg-image wsite-section-effect-parallax wsite-background-6" style={{height: '567px', backgroundImage: 'url("//cdn2.editmysite.com/images/editor/theme-background/stock/Serengeti.jpg")', backgroundRepeat: 'no-repeat', backgroundPosition: '0.00% 88.14%', backgroundSize: 'cover', backgroundColor: 'transparent', backgroundAttachment: 'fixed'}}>
            <div className="wsite-section-content">
              <div className="container">
                <div className="wsite-section-elements">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wsite-section-wrap">
          <div className="wsite-section wsite-body-section wsite-section-bg-image wsite-background-13" style={{height: '281px', backgroundImage: 'url(/images/2016025022.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundColor: 'transparent'}}>
            <div className="wsite-section-content">
              <div className="container">
                <div className="wsite-section-elements">
                  <div id={277409852634374395}>
                    <div>
                      <div id="element-164f7200-7e4d-4314-b461-323bc5206419" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                        <div className="colored-box">
                          <div className="colored-box-content">
                            <div style={{width: 'auto'}}>
                              <div />
                              <div id={579760005108991315}>
                                <div>
                                  <div id="element-2ed8286b-1511-4d11-94c8-626eb6df3797" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                    <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                    <div id="container-box">
                                      <div className="outer-box hover-">
                                        <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                          <div className="content">
                                            <div style={{width: 'auto'}}>
                                              <div />
                                              <div id={358946073191322756}>
                                                <div>
                                                  <div id="element-7a6fe2c2-5f4d-4015-b12e-15232790d81e" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                    <div className="colored-box">
                                                      <div className="colored-box-content">
                                                        <div style={{width: 'auto'}}>
                                                          <div />
                                                          <h2 className="wsite-content-title" style={{textAlign: 'center'}}>
                                                            <strong>
                                                              <font size={6} color="#ffffff">TOKENOMICS</font>
                                                            </strong>
                                                          </h2>
                                                          <div className="paragraph" style={{textAlign: 'center'}}><span style={{color: 'rgb(255, 255, 255)'}}><strong>TRAVEL
                                                                COIN</strong> token $TVL are utility tokens that have
                                                              real value and can be used for all transactions in the
                                                              <strong> TRAVEL COIN</strong> ecosystem.&nbsp;&nbsp;The
                                                              intention is for the launch to be on the BSC
                                                              blockchain.&nbsp;</span>​</div>
                                                          <div className="paragraph" style={{textAlign: 'center'}}>
                                                            <font color="#ffffff"><strong>Name: TRAVEL
                                                                COIN<br />Symbol: $TVL<br /></strong></font>
                                                            <font color="#ffffff">Circulation supply:
                                                              388,888,888,888,888 (388.8
                                                              trillion&nbsp;tokens)<br />Total supply:
                                                              388,888,888,888,888 (388.8
                                                              trillion&nbsp;tokens)<br />Max supply:
                                                              777,777,777,777,777
                                                              (777.7&nbsp;trillion&nbsp;tokens)</font>
                                                            <br /><span />
                                                            <font color="#ffffff">
                                                              &nbsp;<br /><strong>Distribution</strong><br />Team -
                                                              8%<br />Token sale &amp; presale - 50%<br />Userbase -
                                                              20%<br />Strategic partnerships &amp; acquisitions -
                                                              20%<br />Marketing - 2%</font>
                                                          </div>
                                                          <div>
                                                            <div style={{height: '20px', overflow: 'hidden', width: '100%'}}>
                                                            </div>
                                                            <hr className="styled-hr" style={{width: '100%'}} />
                                                            <div style={{height: '20px', overflow: 'hidden', width: '100%'}}>
                                                            </div>
                                                          </div>
                                                          <h2 className="wsite-content-title" style={{textAlign: 'center'}}>
                                                            <strong>
                                                              <font size={6} color="#ffffff"> TRAVEL COIN 
                                                                $TVL<br /> ​TOKEN STRUCTURE</font>
                                                            </strong>
                                                          </h2>
                                                          <div>
                                                            <div className="wsite-image wsite-image-border-none " style={{paddingTop: '10px', paddingBottom: '10px', marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                                                              <a>
                                                                <img src="/images/screenshot-2021-11-22-at-02-42-10.png" alt="Picture" style={{width: 778, maxWidth: '100%'}} />
                                                              </a>
                                                              <div style={{display: 'block', fontSize: '90%'}} />
                                                            </div>
                                                          </div>
                                                          <div id={281223875976089639}>
                                                            <div>
                                                              <div id="element-4f38e35e-3839-4136-a26a-e539e4ea6ac4" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                <div className="colored-box">
                                                                  <div className="colored-box-content">
                                                                    <div style={{width: 'auto'}}>
                                                                      <div />
                                                                      <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong>
                                                                          <font size={6} color="#ffffff">TOKEN
                                                                            FEATURES and FEES</font>
                                                                        </strong></h2>
                                                                      <div className="paragraph">
                                                                        <font color="#ffffff"><strong>
                                                                            <font size={4}>Reward to holders</font>
                                                                          </strong><br />​Earn automatic $TVL
                                                                          rewards by holding your Travel Coin –
                                                                          4% of every buy and sell transaction is
                                                                          redistributed to all token holders directly
                                                                          in wallets. You will receive the rewards
                                                                          automatically in your wallet as $BUSD or claim your
                                                                          rewards manually in our dApp Dashboard
                                                                          anytime.</font>
                                                                      </div>
                                                                      <div className="paragraph">
                                                                        <font color="#ffffff"><strong>
                                                                            <font size={4}>Liquidity fees pool</font>
                                                                          </strong><br />​Our Smart contract
                                                                          deposits 5% from every buy/sell transaction
                                                                          as $TVL liquidity pool.&nbsp;<br />Every
                                                                          Buy/Sell trade is matched with liquidity in
                                                                          the liquidity pool. A bigger liquidity pool
                                                                          will provide more stability to $TVL
                                                                          price.&nbsp;​</font>
                                                                      </div>
                                                                      <div className="paragraph">
                                                                        <font color="#ffffff"><strong>
                                                                            <font size={4}>Buy-back &amp; burn wallet
                                                                            </font>
                                                                          </strong><br />​1% of tokens are
                                                                          collected from every transaction are stored
                                                                          in our contract. This wallet is known as
                                                                          Buy-back wallet, when enabled it purchases
                                                                          $TVL from exchanges and removes the
                                                                          purchased tokens permanently from
                                                                          circulating supply while simultaneously
                                                                          making the market bullish.</font>
                                                                      </div>
                                                                      <div className="paragraph"><strong style={{color: 'rgb(255, 255, 255)'}}>
                                                                          <font size={4}>Marketing development</font>
                                                                        </strong><br /><span style={{color: 'rgb(255, 255, 255)'}}>​4%
                                                                          of tokens are collected for a marketing
                                                                          development fund to include but not limited
                                                                          to platform developments, ecosystem
                                                                          expansion, exchange listings, exhibitions
                                                                          and stakeholders development.</span></div>
                                                                      <div className="paragraph">
                                                                        <font color="#ffffff"><strong>
                                                                            <font size={4}>Sustainable development
                                                                              fund</font>
                                                                          </strong><br />​1% of every
                                                                          transaction is automatically
                                                                          collected&nbsp;for sustainable development
                                                                          purposes. &nbsp;Some of which will be
                                                                          invested into the
                                                                          NFT&nbsp;community&nbsp;projects,
                                                                          stakeholders pledges and partnerships on
                                                                          sustainable
                                                                          tourism&nbsp;development&nbsp;projects.&nbsp;​​
                                                                        </font>
                                                                      </div>
                                                                      <div className="paragraph">
                                                                        <font color="#ffffff"><strong>
                                                                            <font size={4}>Anti-whale protection
                                                                            </font>
                                                                          </strong>&nbsp; <br /> travel coin has a
                                                                          maximum circulating supply of tokens.
                                                                          &nbsp;There will be a maximum number of
                                                                          tokens per transaction (0.01%) and per wallet
                                                                          to nullify whale manipulation. &nbsp;No
                                                                          wallet can exceed 0.1% holdings. </font>&nbsp;
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div style={{clear: 'both'}} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div style={{clear: 'both'}} />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                  </div>
                                  <div style={{clear: 'both'}} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{clear: 'both'}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wsite-section-wrap">
          <div className="wsite-section wsite-body-section wsite-section-bg-image wsite-section-effect-parallax wsite-background-11" style={{height: '861px', backgroundImage: 'url(/images/1163505377.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: '0.00% 70.97%', backgroundSize: 'cover', backgroundColor: 'transparent', backgroundAttachment: 'fixed'}}>
            <div className="wsite-section-content">
              <div className="container">
                <div className="wsite-section-elements">
                  <div id={423816905414107813}>
                    <div>
                      <div id="element-612f4dbf-8f47-44fb-bf5e-42469598824e" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                        <div className="colored-box">
                          <div className="colored-box-content">
                            <div style={{width: 'auto'}}>
                              <div />
                              <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong>
                                  <font size={6} color="#ffffff">BLOCKCHAIN TECHNOLOGY</font>
                                </strong></h2>
                              <div className="paragraph" style={{textAlign: 'left'}}>
                                <font color="#ffffff">Useful tool which will be the primary method to store, trade or
                                  transact&nbsp;digital and tangible assets in the future.<br />Cryptocurrency allows
                                  users to send and receive data in a decentralised&nbsp;and transparent way without
                                  the involvement of external bodies and third&nbsp;parties.<br />Cryptocurrency is
                                  widely adopted because of the non-existent need for an&nbsp;intermediary such as a
                                  financial institution that would otherwise augment&nbsp;the costs of
                                  transactions.<br /><br /><strong> TRAVEL COIN</strong> will seek leverage and the
                                  advantages of cryptocurrency and&nbsp;blockchain technology to give consumers the
                                  rewards of this difference in&nbsp;lower costing and clear processing of data to all
                                  stakeholders.<br /><br /><strong> TRAVEL COIN </strong> 
                                  will adopt this formula and provide an ecosystem with several highly-desirable features across the BSC blockchain.</font>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{clear: 'both'}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wsite-section-wrap">
          <div className="wsite-section wsite-body-section wsite-section-bg-image wsite-background-5" style={{height: 'auto', backgroundImage: 'url(/images/1854387037.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundColor: 'transparent'}}>
            <div className="wsite-section-content">
              <div className="container">
                <div className="wsite-section-elements">
                  <div id={722965025861089305}>
                    <div>
                      <div id="element-bb78a2eb-6692-4b39-aaf0-5f0eb1d4230c" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                        <div id="container-box">
                          <div className="outer-box hover-">
                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                              <div className="content">
                                <div style={{width: 'auto'}}>
                                  <div />
                                  <div id={190726751148556134}>
                                    <div>
                                      <div id="element-c80238fb-42de-4821-91a1-d9b914d5f55a" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                        <div id="container-box">
                                          <div className="outer-box hover-">
                                            <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                              <div className="content">
                                                <div style={{width: 'auto'}}>
                                                  <div />
                                                  <div id={253487576577918617}>
                                                    <div>
                                                      <div id="element-69633532-c381-4b59-a58e-21e2b5d38a82" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                        <div className="colored-box">
                                                          <div className="colored-box-content">
                                                            <div style={{width: 'auto'}}>
                                                              <div />
                                                              <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong>
                                                                  <font size={6}>ROADMAP</font>
                                                                </strong></h2>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                  <div className="wsite-spacer" style={{height: '10px'}} />
                                                  <div id={343483043167106093}>
                                                    <div>
                                                      <div id="element-c3c73f0a-4ec8-4d1c-b3d1-a8fecdb7d7a4" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                        <div className="colored-box">
                                                          <div className="colored-box-content">
                                                            <div style={{width: 'auto'}}>
                                                              <div />
                                                              <div>
                                                                <div className="wsite-multicol">
                                                                  <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                                    <table className="wsite-multicol-table">
                                                                      <tbody className="wsite-multicol-tbody">
                                                                        <tr className="wsite-multicol-tr">
                                                                          <td className="wsite-multicol-col" style={{width: '50%', padding: '0 15px'}}>
                                                                            <div id={473717269986244727}>
                                                                              <div>
                                                                                <div id="element-441786d2-0796-4a73-aac8-485194a92eae" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                                  <div className="colored-box">
                                                                                    <div className="colored-box-content">
                                                                                      <div style={{width: 'auto'}}>
                                                                                        <div />
                                                                                        <div className="paragraph">
                                                                                          <strong>
                                                                                            <font size={4}>Phase 1 -
                                                                                              Q4 2021</font>
                                                                                          </strong>
                                                                                          <ul>
                                                                                            <li>Logo and graphics
                                                                                              designed</li>
                                                                                            <li>Website domains
                                                                                              acquired</li>
                                                                                            <li>Trademarks assigned
                                                                                            </li>
                                                                                            <li>Launch of website</li>
                                                                                            <li>Marketing and pre-sale
                                                                                            </li>
                                                                                            <li>Development of smart
                                                                                              contracts</li>
                                                                                            <li>Development of wallets
                                                                                              and DApps</li>
                                                                                            <li>Pre-launch of NFT
                                                                                              marketplace</li>
                                                                                            <li>Partnerships and
                                                                                              licensing agreements
                                                                                              with travel trade
                                                                                              industry partners.</li>
                                                                                            <li>Creation and start of
                                                                                              creating <strong>TRAVEL
                                                                                                COIN</strong> NFTs
                                                                                            </li>
                                                                                            <li>Coingecko and
                                                                                              Coinmarketcap listings
                                                                                            </li>
                                                                                          </ul>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                                <div style={{clear: 'both'}} />
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                          <td className="wsite-multicol-col" style={{width: '50%', padding: '0 15px'}}>
                                                                            <div id={822854133961248242}>
                                                                              <div>
                                                                                <div id="element-865ac0d9-30ca-4deb-bd34-efa79aef1f51" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                                                  <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                                  </div>
                                                                                  <div id="container-box">
                                                                                    <div className="outer-box hover-">
                                                                                      <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                                                        <div className="content">
                                                                                          <div style={{width: 'auto'}}>
                                                                                            <div />
                                                                                            <div>
                                                                                              <div className="wsite-image wsite-image-border-none " style={{paddingTop: '0px', paddingBottom: '0px', marginLeft: '0px', marginRight: '0px', textAlign: 'center'}}>
                                                                                                <a>
                                                                                                  <img src="/images/depositphotos-102970558-xl-2015.jpg" alt="Picture" style={{width: 450, maxWidth: '100%'}} />
                                                                                                </a>
                                                                                                <div style={{display: 'block', fontSize: '90%'}}>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                            <div>
                                                                                              <div className="wsite-image wsite-image-border-none " style={{paddingTop: '0px', paddingBottom: '0px', marginLeft: '0px', marginRight: '0px', textAlign: 'center'}}>
                                                                                                <a>
                                                                                                  <img src="/images/depositphotos-45219959-xl-2015.jpg" alt="Picture" style={{width: 326, maxWidth: '100%'}} />
                                                                                                </a>
                                                                                                <div style={{display: 'block', fontSize: '90%'}}>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                                  </div>
                                                                                </div>
                                                                                <div style={{clear: 'both'}} />
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                  <div className="wsite-spacer" style={{height: '10px'}} />
                                                  <div id={718535310717789324}>
                                                    <div>
                                                      <div id="element-9587d57e-5f8f-446b-9528-92f7a88681cf" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                        <div className="colored-box">
                                                          <div className="colored-box-content">
                                                            <div style={{width: 'auto'}}>
                                                              <div />
                                                              <div>
                                                                <div className="wsite-multicol">
                                                                  <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                                    <table className="wsite-multicol-table">
                                                                      <tbody className="wsite-multicol-tbody">
                                                                        <tr className="wsite-multicol-tr">
                                                                          <td className="wsite-multicol-col" style={{width: '50%', padding: '0 15px'}}>
                                                                            <div id={985371952361169933}>
                                                                              <div>
                                                                                <div id="element-5fab8c38-024d-4727-9cc7-82b194b02ae9" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                                                  <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                                  </div>
                                                                                  <div id="container-box">
                                                                                    <div className="outer-box hover-">
                                                                                      <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                                                        <div className="content">
                                                                                          <div style={{width: 'auto'}}>
                                                                                            <div />
                                                                                            <div>
                                                                                              <div className="wsite-image wsite-image-border-none " style={{paddingTop: '0px', paddingBottom: '0px', marginLeft: '0px', marginRight: '0px', textAlign: 'center'}}>
                                                                                                <a>
                                                                                                  <img src="/images/bigstock-gondolas-in-venice-sunset-wi-119795360_orig.jpg" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                                                </a>
                                                                                                <div style={{display: 'block', fontSize: '90%'}}>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                            <div>
                                                                                              <div className="wsite-image wsite-image-border-none " style={{paddingTop: '0px', paddingBottom: '0px', marginLeft: '0px', marginRight: '0px', textAlign: 'center'}}>
                                                                                                <a>
                                                                                                  <img src="/images/depositphotos-179730740-xl-2015.jpg" alt="Picture" style={{width: 450, maxWidth: '100%'}} />
                                                                                                </a>
                                                                                                <div style={{display: 'block', fontSize: '90%'}}>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                                  </div>
                                                                                </div>
                                                                                <div style={{clear: 'both'}} />
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                          <td className="wsite-multicol-col" style={{width: '50%', padding: '0 15px'}}>
                                                                            <div id={671857658763294304}>
                                                                              <div>
                                                                                <div id="element-d40f793f-7eeb-4ba0-af0d-aae814031cef" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                                  <div className="colored-box">
                                                                                    <div className="colored-box-content">
                                                                                      <div style={{width: 'auto'}}>
                                                                                        <div />
                                                                                        <div className="paragraph">
                                                                                          <strong>
                                                                                            <font size={4}>Phase 2 -
                                                                                              Q1 2022</font>
                                                                                          </strong>
                                                                                          <ul>
                                                                                            <li>NFT marketplace launch
                                                                                            </li>
                                                                                            <li>Release more<strong> TRAVEL
                                                                                                COIN</strong>&nbsp;NFTs
                                                                                            </li>
                                                                                            <li>Acquisition of
                                                                                              “Parcels”
                                                                                              and space within
                                                                                              Virtual TravelCoin  Metaverse
                                                                                              
                                                                                            </li>
                                                                                            <li>Metaverse build</li>
                                                                                            <li>CEX listing</li>
                                                                                            <li>Launch of NFT farming
                                                                                              platform</li>
                                                                                            <li>New partnerships and
                                                                                              licensing agreements
                                                                                              with travel trade
                                                                                              industry partners.</li>
                                                                                          </ul>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                                <div style={{clear: 'both'}} />
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                  <div className="wsite-spacer" style={{height: '10px'}} />
                                                  <div id={451831841483109989}>
                                                    <div>
                                                      <div id="element-0c805be9-066e-497e-9b87-0aa5eed32551" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                        <div className="colored-box">
                                                          <div className="colored-box-content">
                                                            <div style={{width: 'auto'}}>
                                                              <div />
                                                              <div>
                                                                <div className="wsite-multicol">
                                                                  <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                                    <table className="wsite-multicol-table">
                                                                      <tbody className="wsite-multicol-tbody">
                                                                        <tr className="wsite-multicol-tr">
                                                                          <td className="wsite-multicol-col" style={{width: '50%', padding: '0 15px'}}>
                                                                            <div id={531739046282125146}>
                                                                              <div>
                                                                                <div id="element-10e897bb-67c8-4885-8134-390c80de019f" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                                  <div className="colored-box">
                                                                                    <div className="colored-box-content">
                                                                                      <div style={{width: 'auto'}}>
                                                                                        <div />
                                                                                        <div className="paragraph">
                                                                                          <strong>
                                                                                            <font size={4}>Phase 3 -
                                                                                              Q2 2022</font>
                                                                                          </strong>
                                                                                          <ul>
                                                                                            <li>Release of Farm and
                                                                                              Staking pools</li>
                                                                                            <li>Metaverse buildout for
                                                                                              different tourism
                                                                                              destinations</li>
                                                                                            <li><strong>TRAVEL
                                                                                                COIN</strong> website
                                                                                              updated</li>
                                                                                            <li>More exchange listing
                                                                                            </li>
                                                                                            <li>Developing new
                                                                                              partnership&nbsp;agreements
                                                                                              with travel trade
                                                                                              industry partners.</li>
                                                                                          </ul>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                                <div style={{clear: 'both'}} />
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                          <td className="wsite-multicol-col" style={{width: '50%', padding: '0 15px'}}>
                                                                            <div id={480722131413516525}>
                                                                              <div>
                                                                                <div id="element-4672a53c-2449-4d64-8705-853c2d410271" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                                                  <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                                  </div>
                                                                                  <div id="container-box">
                                                                                    <div className="outer-box hover-">
                                                                                      <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                                                        <div className="content">
                                                                                          <div style={{width: 'auto'}}>
                                                                                            <div />
                                                                                            <div>
                                                                                              <div className="wsite-image wsite-image-border-none " style={{paddingTop: '0px', paddingBottom: '0px', marginLeft: '0px', marginRight: '0px', textAlign: 'center'}}>
                                                                                                <a>
                                                                                                  <img src="/images/depositphotos-37649591-xl-2015.jpg" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                                                </a>
                                                                                                <div style={{display: 'block', fontSize: '90%'}}>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                            <div>
                                                                                              <div className="wsite-image wsite-image-border-none " style={{paddingTop: '0px', paddingBottom: '0px', marginLeft: '0px', marginRight: '0px', textAlign: 'center'}}>
                                                                                                <a>
                                                                                                  <img src="/images/depositphotos-43389947-xl-2015.jpg" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                                                </a>
                                                                                                <div style={{display: 'block', fontSize: '90%'}}>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                                  </div>
                                                                                </div>
                                                                                <div style={{clear: 'both'}} />
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                  <div className="wsite-spacer" style={{height: '10px'}} />
                                                  <div id={961635136324887176}>
                                                    <div>
                                                      <div id="element-adbb0d3e-1848-4e15-b5ed-26fc9f8a025a" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                        <div className="colored-box">
                                                          <div className="colored-box-content">
                                                            <div style={{width: 'auto'}}>
                                                              <div />
                                                              <div>
                                                                <div className="wsite-multicol">
                                                                  <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                                    <table className="wsite-multicol-table">
                                                                      <tbody className="wsite-multicol-tbody">
                                                                        <tr className="wsite-multicol-tr">
                                                                          <td className="wsite-multicol-col" style={{width: '50%', padding: '0 15px'}}>
                                                                            <div id={544950854276430918}>
                                                                              <div>
                                                                                <div id="element-065f7c2b-8b5d-45dc-91c5-ce0ae14545ab" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                                                                  <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                                  </div>
                                                                                  <div id="container-box">
                                                                                    <div className="outer-box hover-">
                                                                                      <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                                                        <div className="content">
                                                                                          <div style={{width: 'auto'}}>
                                                                                            <div />
                                                                                            <div>
                                                                                              <div className="wsite-image wsite-image-border-none " style={{paddingTop: '0px', paddingBottom: '0px', marginLeft: '0px', marginRight: '0px', textAlign: 'center'}}>
                                                                                                <a>
                                                                                                  <img src="/images/depositphotos-362752106-xl-2015.jpg" alt="Picture" style={{width: 450, maxWidth: '100%'}} />
                                                                                                </a>
                                                                                                <div style={{display: 'block', fontSize: '90%'}}>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                            <div>
                                                                                              <div className="wsite-image wsite-image-border-none " style={{paddingTop: '0px', paddingBottom: '0px', marginLeft: '0px', marginRight: '0px', textAlign: 'center'}}>
                                                                                                <a>
                                                                                                  <img src="/images/depositphotos-32883393-xl-2015_orig.jpg" alt="Picture" style={{width: 'auto', maxWidth: '100%'}} />
                                                                                                </a>
                                                                                                <div style={{display: 'block', fontSize: '90%'}}>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div style={{height: 'calc(0*1px)', overflow: 'hidden', width: '100%'}}>
                                                                                  </div>
                                                                                </div>
                                                                                <div style={{clear: 'both'}} />
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                          <td className="wsite-multicol-col" style={{width: '50%', padding: '0 15px'}}>
                                                                            <div id={951192877838153007}>
                                                                              <div>
                                                                                <div id="element-fbfb7885-a2e7-41cd-9e5b-e7010131c556" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                                  <div className="colored-box">
                                                                                    <div className="colored-box-content">
                                                                                      <div style={{width: 'auto'}}>
                                                                                        <div />
                                                                                        <div className="paragraph">
                                                                                          <strong>
                                                                                            <font size={4}>Phase 4 -
                                                                                              Q3 2022</font>
                                                                                          </strong>
                                                                                          <ul>
                                                                                            <li>Listing on major
                                                                                              exchanges</li>
                                                                                            <li>Marketing and
                                                                                              promotion development
                                                                                            </li>
                                                                                            <li><strong>TRAVEL
                                                                                                COIN</strong> DApp
                                                                                              stake earn tokens
                                                                                              (mobile devices iOS and
                                                                                              Android)</li>
                                                                                            <li>More strategic
                                                                                              partnerships with the
                                                                                              travel and tourism
                                                                                              industry</li>
                                                                                          </ul>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                                <div style={{clear: 'both'}} />
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div style={{clear: 'both'}} />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                      </div>
                                      <div style={{clear: 'both'}} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                      </div>
                      <div style={{clear: 'both'}} />
                    </div>
                  </div>
                  <div className="wsite-spacer" style={{height: '50px'}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <div className="mini-cart-screen" />
  </div>
  )
}

export default SiteCanvas