import React, { useEffect } from 'react'
import Footer from '../../components/Footer';

function SiteCanvas(params) {

  useEffect(() => {
    
    document.getElementById("video-iframe-402961661183896539").style.opacity = 0;
		document.getElementById("wsite-video-container-402961661183896539").style.background = "none";
		var videoIframe402961661183896539Content = '<!DOC'+'TYPE ht'+'ml>'
			+ '<he'+'ad>'
			+ '<scr'+'ipt type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.4/jquery.min.js">\x3C/script>'
			+ '<scr'+'ipt type="text/javascript" src="/js/weebly-generateVideo.js">\x3C/script>'
			+ '</he'+'ad>'
			+ '<bo'+'dy style="margin:0; padding: 0;border:none;overflow:hidden;">'
			+ '</bo'+'dy></ht'+'ml>';
		setTimeout(function() {
			var videoIframe402961661183896539Actual =  document.getElementById("video-iframe-402961661183896539").contentDocument;
			videoIframe402961661183896539Actual.open().write(videoIframe402961661183896539Content);
			videoIframe402961661183896539Actual.close();
		}, 500);

  }, [])

  return (
    <div className="site-canvas">
      <div className="banner-wrap">
        <div className="wsite-elements wsite-not-footer wsite-header-elements">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-header-section wsite-section-bg-gradient" style={{height: 'auto', background: 'linear-gradient(180deg, #000000 0%, #063970 100%)'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="banner">
                    <div className="wsite-section-elements">
                      <div>
                        <div className="wsite-image wsite-image-border-none " style={{paddingTop: 0, paddingBottom: 0, marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                          <a>
                            <img src="/images/virtual-travel-logo-updated-ver3-copy.png" alt="Picture" style={{width: 614, maxWidth: '100%'}} />
                          </a>
                          <div style={{display: 'block', fontSize: '90%'}} />
                        </div>
                      </div>
                      <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong>
                          <font color="#d29502">VIRTUAL TRAVEL</font>
                        </strong></h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-wrap">
        <div id="wsite-content" className="wsite-elements wsite-not-footer">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-background-58" style={{height: 'auto'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-section-bg-gradient wsite-background-22" style={{background: 'linear-gradient(180deg, #063970 0%, #020202 100%)'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <div id={460690864810130509}>
                      <div>
                        <div id="element-a3e25513-1b87-44d3-aea7-b5022ef59ab6" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                          <div id="container-box">
                            <div className="outer-box hover-">
                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                <div className="content">
                                  <div style={{width: 'auto'}}>
                                    <div />
                                    <div id={341624793559534364}>
                                      <div>
                                        <div id="element-307f8874-a3e1-42b6-9ed5-bec8c639e935" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                          <div id="container-box">
                                            <div className="outer-box hover-">
                                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                <div className="content">
                                                  <div style={{width: 'auto'}}>
                                                    <div />
                                                    <div id={939144543328056729}>
                                                      <div>
                                                        <div id="element-6173c272-7487-4159-b07f-021b4c12f0b2" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                          <div className="colored-box">
                                                            <div className="colored-box-content">
                                                              <div style={{width: 'auto'}}>
                                                                <div />
                                                                <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong><a href="http://www.virtualtravel.tv" target="_blank">
                                                                      <font color="#2a2a2a">VIRTUALTRAVEL.TV</font>
                                                                    </a></strong></h2>
                                                                <div className="paragraph">
                                                                  <strong>VIRTUALTRAVEL.TV&nbsp;</strong>is a virtual
                                                                  marketplace and digital platform for global business
                                                                  exchange in the travel industry. <strong>VIRTUAL
                                                                    TRAVEL</strong>&nbsp;connects travel professionals
                                                                  from over 200+ countries to conduct sustainable
                                                                  business and promote travel sales direct to FITs.
                                                                  &nbsp;It is here where you will be able to experience
                                                                  and connect to the <strong> VIRTUAL TRAVEL </strong>
                                                                  metaverse.<br /><br />Technology today has allowed us
                                                                  to experience travel without having to be there
                                                                  physically, and what makes the experience different
                                                                  between being there in person and seeing them on video
                                                                  is the first-person experience. This is where the
                                                                  metaverse, Augmented Reality(AR) and Virtual
                                                                  Reality(VR), come into the picture.<br /><br />An
                                                                  immersive world with realistic content will enhance
                                                                  the audience's imagination and allow them to enjoy the
                                                                  location through an amazing first-person experience
                                                                  which is like being there in the first
                                                                  place.<br /><br />A major Metaverse will be built that
                                                                  will include NFT wearables, drivables and NFT
                                                                  keys.&nbsp;&nbsp;These keys will give VIP access to
                                                                  certain areas in the <strong style={{color: 'rgb(42, 42, 42)'}}> TRAVEL COIN </strong>
                                                                  <strong>VIRTUAL TRAVEL</strong> Metaverse that will
                                                                  include virtual experiences of different destinations
                                                                  around the world, watch videos and be immersed in AI
                                                                  technology as if you are on location our at the
                                                                  destination.</div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{clear: 'both'}} />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                        </div>
                                        <div style={{clear: 'both'}} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                        </div>
                        <div style={{clear: 'both'}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-section-bg-image wsite-background-21" style={{height: 627, backgroundImage: 'url(/images/221577395.png)', backgroundRepeat: 'no-repeat', backgroundPosition: '0.00% 37.05%', backgroundSize: 'cover', backgroundColor: 'transparent'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <blockquote style={{textAlign: 'left'}}>
                      <font color="#ffed00"><strong>ENABLING BLOCKCHAIN TECHNOLOGIES TO SHAPE THE TOURISM ECOSYSTEM VIA
                          VIRTUALTRAVEL.TV AND TRAVEL COIN TO BE THE UNIVERSAL TOKEN</strong>.</font>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-background-16" style={{height: 'auto'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <div id={645946446728958000}>
                      <div>
                        <div id="element-476ef99b-2af3-4ddc-9589-a123a1b55603" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                          <div id="container-box">
                            <div className="outer-box hover-">
                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                <div className="content">
                                  <div style={{width: 'auto'}}>
                                    <div />
                                    <div id={209499847705611654}>
                                      <div>
                                        <div id="element-26707baa-922f-44ab-9bbb-292a458c7d8e" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                          <div id="container-box">
                                            <div className="outer-box hover-">
                                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                <div className="content">
                                                  <div style={{width: 'auto'}}>
                                                    <div />
                                                    <div id={594082654335313995}>
                                                      <div>
                                                        <div id="element-23503e66-8128-40e0-a7bc-ddb79e78a4a9" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                          <div className="colored-box">
                                                            <div className="colored-box-content">
                                                              <div style={{width: 'auto'}}>
                                                                <div />
                                                                <h2 className="wsite-content-title" style={{textAlign: 'center'}}><strong>VIRTUAL TRAVEL
                                                                    EXPERIENCE</strong></h2>
                                                                <div className="paragraph"><span><strong>VIRTUAL
                                                                      TRAVEL</strong> is a new frontier that is yet to
                                                                    be adopted into the mainstream. Other major content
                                                                    hosting services are growing their repositories of
                                                                    360° video content. However, unlike conventional
                                                                    travel which is typically associated with freedom,
                                                                    current video content only allows you to view what
                                                                    has been recorded for you to
                                                                    see.</span><br /><br />Another use case of bringing
                                                                  tourism into virtual reality is showcasing famous
                                                                  UNESCO Heritage sites around the world, allowing you
                                                                  to explore the most unique and protected destinations
                                                                  in the world.<br /><br /></div>
                                                                <div id={259013743400331449}>
                                                                  <div>
                                                                    <div id="element-653e7493-0b30-4492-ad51-150af9399319" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                      <div className="colored-box">
                                                                        <div className="colored-box-content">
                                                                          <div style={{width: 'auto'}}>
                                                                            <div />
                                                                            <div className="paragraph" style={{textAlign: 'center'}}><strong>Petra,
                                                                                Jordan, UNESCO World Heritage
                                                                                Site</strong></div>
                                                                                <div className="wsite-video">
                                                                                  <div title="Video: vr_jordan_730.mp4" className="wsite-video-wrapper wsite-video-height-auto wsite-video-align-center">
                                                                                    <div id="wsite-video-container-402961661183896539" className="wsite-video-container" style={{margin: '0px 0 0px 0'}}>
                                                                                      <iframe title="Video: vr_jordan_730.mp4" allowTransparency="true" allowFullScreen="true" frameBorder={0} scrolling="no" id="video-iframe-402961661183896539" src="about:blank"></iframe>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div style={{clear: 'both'}} />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{clear: 'both'}} />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                        </div>
                                        <div style={{clear: 'both'}} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                        </div>
                        <div style={{clear: 'both'}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-section-bg-image wsite-section-effect-parallax wsite-background-20" style={{height: 669, backgroundImage: 'url("//cdn2.editmysite.com/images/editor/theme-background/stock/City-Paris.jpg")', backgroundRepeat: 'no-repeat', backgroundPosition: '50.00% 1.67%', backgroundSize: 'cover', backgroundColor: 'transparent', backgroundAttachment: 'fixed'}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-background-19">
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <div id={724060888838109597}>
                      <div>
                        <div id="element-7b8ad7ff-91cc-4635-ae9d-798015204eec" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                          <div id="container-box">
                            <div className="outer-box hover-">
                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                <div className="content">
                                  <div style={{width: 'auto'}}>
                                    <div />
                                    <div id={369874303274557695}>
                                      <div>
                                        <div id="element-b66ed129-4d93-442f-a76a-1f31f0938362" data-platform-element-id="143161353224782035-1.6.0" className="platform-element-contents">
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                          <div id="container-box">
                                            <div className="outer-box hover-">
                                              <div className="color-box  border- borderSides-All shadow-1 shadowVert- shadowHor-">
                                                <div className="content">
                                                  <div style={{width: 'auto'}}>
                                                    <div />
                                                    <div id={578412103236308487}>
                                                      <div>
                                                        <div id="element-e0b0fb2b-446c-4650-8aa6-99371cf3e7d3" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                          <div className="colored-box">
                                                            <div className="colored-box-content">
                                                              <div style={{width: 'auto'}}>
                                                                <div />
                                                                <h2 className="wsite-content-title" style={{textAlign: 'center'}}>
                                                                  <strong>VIRTUALTRAVEL.TV<br />BLOCKCHAIN</strong></h2>
                                                                <div className="paragraph">
                                                                  <font color="#000000"><strong>Augmented
                                                                      reality/Virtual Reality
                                                                      (AR/VR)</strong><br />Augmented reality systems
                                                                    show virtual objects in the real world. Uses in
                                                                    tourism can include replacing paper-based marketing
                                                                    and advertising materials, gamification and
                                                                    augmented visitor experiences in the destination,
                                                                    and travel assistants that guide users through
                                                                    complex public transport systems in real
                                                                    time.<br /><br /><strong>Artificial Intelligence
                                                                      (AI)</strong><br />Artificial intelligence,
                                                                    chatbots, and voice technology enable customers to
                                                                    undertake internet searches, digital check-in,
                                                                    access digital concierge services, voice assistants
                                                                    and smart rooms.&nbsp;<br /><br />
                                                                    <font size={3}><strong>Mobile technology/cloud
                                                                        computing</strong></font><br />Cloud
                                                                    technologies, Wi-Fi and international mobile plans
                                                                    make mobile devices increasingly pervasive and
                                                                    important travel aids, including for accessing
                                                                    destination information in real time, online
                                                                    booking, mobile payment.<br /><br />
                                                                    <font size={3}><strong>Blockchain</strong></font>
                                                                    <br />Smart contracts, based on blockchain, can be
                                                                    used across the supply chain. Future gains would see
                                                                    user-friendly apps tailored for wide diffusion to
                                                                    tourism businesses of all sizes to enhance
                                                                    end-to-end user transparency.
                                                                  </font>
                                                                </div>
                                                                <div className="paragraph" style={{textAlign: 'center'}}>
                                                                  <strong>
                                                                    <font size={4} color="#2a2a2a">BENEFITS OF
                                                                      BLOCKCHAIN-ENABLED SMART TOURISM DESTINATION FROM
                                                                      A HOLISTIC VIEW OF SUSTAINABILITY</font>
                                                                  </strong></div>
                                                                <div>
                                                                  <div className="wsite-multicol">
                                                                    <div className="wsite-multicol-table-wrap" style={{margin: '0 -15px'}}>
                                                                      <table className="wsite-multicol-table">
                                                                        <tbody className="wsite-multicol-tbody">
                                                                          <tr className="wsite-multicol-tr">
                                                                            <td className="wsite-multicol-col" style={{width: '14.097797219185%', padding: '0 15px'}}>
                                                                              <div className="wsite-spacer" style={{height: 50}} />
                                                                            </td>
                                                                            <td className="wsite-multicol-col" style={{width: '72.388689267302%', padding: '0 15px'}}>
                                                                              <div id={903688113727865306}>
                                                                                <div>
                                                                                  <div id="element-ba289857-374a-46b8-9456-337749a4af26" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                                    <div className="colored-box">
                                                                                      <div className="colored-box-content">
                                                                                        <div style={{width: 'auto'}}>
                                                                                          <div />
                                                                                          <div>
                                                                                            <div className="wsite-image wsite-image-border-none " style={{paddingTop: 10, paddingBottom: 10, marginLeft: 0, marginRight: 0, textAlign: 'center'}}>
                                                                                              <a>
                                                                                                <img src="/images/screenshot-2021-11-02-at-14-58-07.png" alt="Picture" style={{width: 460, maxWidth: '100%'}} />
                                                                                              </a>
                                                                                              <div style={{display: 'block', fontSize: '90%'}}>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div style={{clear: 'both'}} />
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                            <td className="wsite-multicol-col" style={{width: '13.513513513514%', padding: '0 15px'}}>
                                                                              <div className="wsite-spacer" style={{height: 50}} />
                                                                            </td>
                                                                          </tr>
                                                                        </tbody>
                                                                      </table>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="paragraph" style={{textAlign: 'center'}}>
                                                                  <br /><strong>
                                                                    <font size={4}> ​TRAVEL COIN TOKEN -&nbsp;$TVL
                                                                      BENEFITS</font>
                                                                  </strong></div>
                                                                <div className="paragraph" style={{textAlign: 'left'}}>
                                                                  <ul>
                                                                    <li><strong>​​</strong>Using <strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL
                                                                        COIN</strong> – your rewards/points will
                                                                      be converted to <strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL
                                                                        COIN</strong> tokens and can be used across the
                                                                      travel domains.</li>
                                                                    <li>Fast transactions – secured by smart
                                                                      contracts and useable across a variety of domains.
                                                                    </li>
                                                                    <li>Protection of your booking transactions to
                                                                      secure all sensitive information and identity.
                                                                    </li>
                                                                    <li>Reduced cost of audit and compliance, as every
                                                                      transaction information is Blockchain stored and
                                                                      authenticated.</li>
                                                                    <li><strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL
                                                                        COIN</strong> will become the best way for
                                                                      client to make reservation and send payment from
                                                                      their connected wallet.</li>
                                                                    <li>By verifying all bookings/transactions are
                                                                      executed by blockchain shows that each is booked
                                                                      by genuine clients.</li>
                                                                    <li>Service providers from the Travel and Tourism
                                                                      Trade industry will provide offers in the <strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL
                                                                        COIN</strong> database. &nbsp;These
                                                                      offers/packages will be matched to clients
                                                                      requests or requirements.</li>
                                                                    <li>Prices will already be in <strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL
                                                                        COIN</strong> tokens ($TVL) against fiat
                                                                      currencies.</li>
                                                                    <li>
                                                                      <strong style={{color: 'rgb(42, 42, 42)'}}>TRAVEL COIN</strong> token will be powered by the BSC blockchain.</li>
                                                                  </ul>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div style={{clear: 'both'}} />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                                        </div>
                                        <div style={{clear: 'both'}} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{height: 'calc(5*1px)', overflow: 'hidden', width: '100%'}} />
                        </div>
                        <div style={{clear: 'both'}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className="mini-cart-screen" />
    </div>
  )
}

export default SiteCanvas